export enum DomainchgTypes {
    GET_KEITAROS = "@@domainchg/GET_KEITAROS",
    GET_KEITAROS_SUCCESS = "@@domainchg/GET_KEITAROS_SUCCESS",
    CLEAR_KEITAROS = "@@domainchg/CLEAR_KEITAROS",

    GET_KEITAROS_CAMPAINS = "@@domainchg/GET_KEITAROS_CAMPAINS",
    GET_KEITAROS_CAMPAINS_SUCCESS = "@@domainchg/GET_KEITAROS_CAMPAINS_SUCCESS",
    CLEAR_KEITAROS_CAMPAINS = "@@domainchg/CLEAR_KEITAROS_CAMPAINS",

    KEITAROS_FAIL = "@@domainchg/KEITAROS_FAIL",

    SUBMIT_CHANGE_DOMAIN = "@@domainchg/SUBMIT_CHANGE_DOMAIN",
    SUBMIT_CHANGE_DOMAIN_SUCCESS = "@@domainchg/SUBMIT_CHANGE_DOMAIN_SUCCESS",
}

export interface Campaigns {
    bk_camps: Array<any>;
    wk_camps: Array<any>;
}

export interface DomainchgState {
    keitaros: Array<any>;
    campaigns: Campaigns;
    error: Object;
    loadingKeitaros: boolean,
    loadedKeitaros: boolean,
    loadingCampaigns: boolean,
    loadedCampaigns: boolean,
    loadingChangeDomain: boolean,
    loadedChangeDomain: boolean,
}
