import React, { useMemo } from "react";
import {Form, ModalBody, ModalHeader, Modal, Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";
// import {addTeam, clearTeam, modalTeam, updTeam} from "../../../store/team/actions";
import {clearBroker, modalBroker, getBroker, addBroker, updBroker } from "../../../store/brokers/actions";


interface Props {
  isOpen: boolean;
  //toggle: () => void;
}
interface IFormValues {
  name: string;
  ci: string;
}
const ModalBroker = ({isOpen} : Props) => {
  const dispatch = useDispatch();

  const item = useSelector((state: any) => state.Brokers.item || {});



  const validationSchema: yup.Schema<IFormValues> = yup.object().shape({
    name: yup.string()
      .min(1, "min 1 character")
      .max(75, "max 75 characters")
      .matches(/^[a-zA-Z0-9_-]+$/, "Only latin symbols and numbers")
      .required("required"),
    ci: yup.string()
      .min(1, "min 1 character")
      .max(5, "max 5 characters")
      .matches(/^[0-9]+$/, "Only numbers")
      .required("required"),
  });

  const initialValues = useMemo((): IFormValues => {
    return {
      name: item?.name || "",
      ci: item?.ci || "",
    }
  }, [item]);

  const handleSubmitForm = () => {
    let ci = parseInt(values.ci, 10);
    if(item.id) {
      // console.log(item)
      dispatch( updBroker({ "name": values.name, "ci": values.ci}, item.id  ));
    } else {
    dispatch( addBroker({ "name": values.name, "ci": ci}) );
    }
  };

  const {
    values,
    validateForm,
    handleChange,
    submitForm,
    handleBlur,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true
  });

  const handlerClickSubmit = async () => {
    const curErrors = await validateForm();
    const curErrorsKeys = Object.keys(curErrors);
    if (curErrorsKeys.length) {
      const el = document.getElementById(curErrorsKeys[0]);
      if (el) el.focus();
    }
    submitForm();
  };

  const close = () => {
    dispatch(modalBroker(false));
    dispatch(clearBroker());
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        {item?.id  ? "Edit" : "Add"} Broker
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <LabelInput
                  label="Name*"
                  placeholder="Name*"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ""}
                  hasError={!!(errors.name && touched.name)}
                  errorText={errors.name}
                />
              </div>
              <div className="mb-3">
                <LabelInput
                  label="Ci*"
                  placeholder="Ci*"
                  name="ci"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ci || ""}
                  hasError={!!(errors.ci && touched.ci)}
                  errorText={errors.ci}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={handlerClickSubmit}
                  disabled={!isValid}
                > Save </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalBroker;