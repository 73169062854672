import React, {useEffect, useMemo, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link, useNavigate} from "react-router-dom";
import Select from "../../components/Common/Select";
import {useDispatch, useSelector} from "react-redux";
import {
    clearJiraStatistics,
    clearJiraUsers,
    clearProjects,
    getJiraStatistics,
    getJiraUsers,
    getProjects
} from "../../store/jira/actions";
import Flatpickr from "react-flatpickr";
import {formatDate} from "../../helpers/formatDate";
import NoSearchResult from "../../components/Common/NoSearchResult";
import TableContainer from "../../components/Common/TableContainer";
import moment from "moment";
import usePermissions from "../../hooks/usePermissions";
import ReactApexChart from "react-apexcharts";

const JiraStatistic = () => {
    const dispatch = useDispatch()
    const { hasPermission } = usePermissions();
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [fromDateStr, setFromDateStr] = useState<string>();
    const [toDateStr, setToDateStr] = useState<string>();

    const projects = useSelector((state: any) => state.jira.projects || [] );
    const jiraUsers = useSelector((state: any) => state.jira.users || [] );
    const jiraTasks = useSelector((state: any) => state.jira.statistics || [] )
    const loadingJiraTasks = useSelector((state: any) => state.jira.loadingJiraStatistics)
    const loadedJiraTasks = useSelector((state: any) => state.jira.loadedJiraStatistics)

    console.log("selectedUser", selectedUser)

    useEffect(() => {
        const permission = hasPermission('view-jira');
        if (!permission) {
            navigate('/no-access');
        }
    }, [hasPermission, navigate]);

    useEffect(() => {
        dispatch(getProjects())
        dispatch(getJiraUsers())
        return () => {
            dispatch(clearProjects())
            dispatch(clearJiraUsers())
            dispatch(clearJiraStatistics())
        }
    }, []);

    const projectItems = useMemo(() => {
        if(projects.values.length) {
            return projects.values.map((i: any) => {
                return { value: i.key, label: i.name }
            })
        }
    }, [projects])

    const userItems = useMemo(() => {
        if(jiraUsers.length) {
            return jiraUsers.map((i: any) => {
                return { value: i.accountId, label: i.displayName}
            })
        }
    }, [jiraUsers])

    const formatRangeDate = (dateRange: [Date | null, Date | null]): any => {
        const [fromDate, toDate] = dateRange;
        setFromDateStr(fromDate ? formatDate(fromDate) : "");
        setToDateStr(toDate ? formatDate(toDate) : "");
    }

    const handleSearch = () => {
        const data = {
            'userId': selectedUser.map((i: any) => i.value),
            'projectKey': selectedProject.value,
            "fromDate": fromDateStr,
            "toDate": toDateStr
        }

        dispatch(getJiraStatistics(data))
    }

    const taskList = useMemo(() => {

        if(jiraTasks?.taskDetails) {
            return jiraTasks.taskDetails.map((item: any) => ({
                id: item.id || null,
                name: item.name || null,
                assignee: item.assignee || null,
                created_at: moment(item.created_at).format("YYYY-MM-DD") || null,
            })).filter((item: any) => item !== null)
        }
        return []

    }, [jiraTasks]);


    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Task",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Assignee",
                accessor: "assignee",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
        ], []);

    const userTaskCounts = jiraTasks.userTaskCounts || {};
    const categories = Object.keys(userTaskCounts);
    const data = Object.values(userTaskCounts);

    const state: any = {
        series: [{
            name: 'Tasks Completed',
            data: data
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories,
            }
        },
    };


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Jira Statistic" />
                    <Row>
                        <Col md={12} className="d-flex gap-2 justify-content-end">
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    options={{
                                        mode: "range",
                                        dateFormat: "Y-m-d",
                                    }}
                                    onChange={(date: any) => {
                                        formatRangeDate(date);
                                    }}
                                />
                                <Select
                                    isSearchable
                                    placeholder="Select Team"
                                    options={userItems}
                                    onChange={(selectedOption: any) =>
                                        setSelectedUser(selectedOption)
                                    }
                                    value={selectedUser}
                                    isMulti
                                />
                                <Select
                                    isSearchable
                                    placeholder="Select Teamplate"
                                    options={projectItems}
                                    onChange={(selectedOption: any) =>
                                        setSelectedProject(selectedOption)
                                    }
                                    value={selectedProject}
                                />
                                <div>
                                    <Link to="#" className="btn btn-success" onClick={handleSearch} >
                                        Search
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">

                                    {taskList.length > 0 && <ReactApexChart
                                        options={state.options}
                                        series={state.series}
                                        type="bar"
                                        height={350}
                                    />}

                                    {taskList.length > 0 && <TableContainer
                                        columns={columns}
                                        data={taskList}
                                        isGlobalFilter={true}
                                        customPageSize={100}
                                        className="table align-middle datatable dt-responsive table-check nowrap"
                                    />}
                                    {!loadedJiraTasks ? (<NoSearchResult />) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </>
    )
}

export default JiraStatistic;