import { LeadDashboardTypes, LeadDashboardState} from "./actionTypes";


export const INIT_STATE : LeadDashboardState ={
  kt: [],
  leadData:[],
  loading: false,
  error : "",
  loaded: false,
  isOpenModal: false,
}


const leadDashboard = (state = INIT_STATE, action: any) => {
  switch(action.type){
    case LeadDashboardTypes.GET_KT:
      return {
        ...state,
        loading: true
      }
      case LeadDashboardTypes.GET_KT_SUCCESS:
        return{
          ...state,
          kt: action.payload,
          loaded: true,
          loading: false
        }
      case LeadDashboardTypes.POST_LEADS:
        return{
          ...state,
          loading: true
        }
      case LeadDashboardTypes.POST_LEADS_SUCCES:
        return{
          ...state,
          leadData: action.payload,
          loaded: true,
          loading: false
        }
        case LeadDashboardTypes.MODAL_LEADS:
          return {
              ...state,
              isOpenModal: action.status
          }
      default:
        return state;
  }

}

export default leadDashboard;