import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCheckin, clearCheckin, modalCheckin } from "src/store/actions"
import { useTranslation } from "react-i18next";
import TableCheck from "./components/TableCheck";
import ModalCheckin from "./components/ModalCheckin";
import NoSearchResult from "../../components/Common/NoSearchResult";
import { Link } from "react-router-dom";
import usePermissions from "src/hooks/usePermissions";




const Checkin = () => {
	document.title = "Chekin List | Chekin board";
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { hasPermission } = usePermissions();
	const navigate = useNavigate();

	const data = useSelector((state: any) => state.Checkin.data || []);
	const daysInMonth = useSelector((state: any) => state.Checkin.daysInMonth || [])
	const loading = useSelector((state: any) => state.Checkin.loading || false);
	const isOpenModal = useSelector((state: any) => state.Checkin.isOpenModal || false);






	const handleCheckinClicks = () => {
		dispatch(modalCheckin(true))
	};

	useEffect(() => {
		dispatch(getCheckin({}))

		return () => {
			dispatch(clearCheckin())
		};
	}, []);

//
// 	useEffect(() => {
// 		const permission = hasPermission('view-checkin');
// 		if (!permission) {
// 		navigate('/no-access');
// 		}
// }, [hasPermission, navigate]);

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Checkin" breadcrumbItem="Checkin" />
					<Row>
						<div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
							<div>
								{
									<Link to="#" className="btn btn-light" onClick={handleCheckinClicks}>
										<i className="bx bx-plus me-1"></i> Add New Employee
									</Link>
								}
							</div>
						</div>
						<div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
						</div>
					</Row>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody className="border-bottom">
									{data.length > 0 && <TableCheck data={data} daysInMonth={daysInMonth} />}
									{loading ? (<NoSearchResult />) : null}
								</CardBody>
							</Card>
						</Col>
					</Row>
					{isOpenModal && <ModalCheckin isOpen={true} />}
				</Container>
			</div>
		</>


	);

};

export default Checkin;