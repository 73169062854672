import React, { useMemo } from "react";
import {Form, ModalBody, ModalHeader, Modal, Row, Col, Label} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";
import {addUser, clearUser, modalUser, updUser} from "../../../store/user/actions";
import Select from "../../../components/Common/Select";


interface Props {
  isOpen: boolean;
  //toggle: () => void;
}
interface IFormValues {
  name: string;
  email: string;
  buyer_ai: string;
  team_id: number;
  password: string;
  total: string;
  parent_id: number,
  role_ids: any[],
  tg_id: any;
}

const validationSchema = yup.object().shape({
  name: yup.string()
    .min(1, "min 1 characters")
    .max(75, "max 75 characters")
    .matches(/^[a-zA-Z 0-9_-]+$/, "Only latin symbols and number")
    .required("required"),

  team_id: yup.number()
    .required("Team ID is required"),
  password: yup.string().nullable(),
  total: yup.number().nullable(),
  parent_id: yup.number().nullable(),
  roles: yup.number().nullable(),
  tg_id: yup.number().nullable()
});


const ModalTeam = ({isOpen} : Props) => {
  const dispatch = useDispatch();

  const item = useSelector((state: any) => state.Users.item || {});
  const users = useSelector((state: any) => state.Users.data || {});
  const teams = useSelector((state: any) => state.Teams.data || []);
  const roles = useSelector((state: any) => state.roles.data);

  // console.log(users)

  const resUsers = useMemo(() => (
    users.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
  ), [users]);


  const tgUserId = useMemo(() => (
    users.map((item: any) => ({
      value: item?.account?.id,
      label: item?.account?.tg_name,
    }))
  ), [users]);

  // console.log(tgUserId)

  const teamsData = useMemo(() => (
    teams.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
  ), [teams]);

  const rolData = useMemo(() => (
    roles.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
  ), [roles]);

  const initialValues = useMemo((): IFormValues => {
    return {
      name: item?.name || "",
      email: item?.email || "",
      buyer_ai: item?.buyer_ai || "",
      team_id: item?.team_id || "",
      password: item?.password || "",
      total: item?.total || "",
      parent_id: item.parent_id || null,
      role_ids: item.roles?.map((item: any) => item.id) || "",
      tg_id: item.tg_id || "",
      


    }
  }, [item]);

  const handleSubmitForm = () => {

    const data = { 
      "name": values.name, 
      "email": values.email, 
      "buyer_ai": Number(values.buyer_ai), 
      "team_id": Number(values.team_id), 
      "password": values.password, 
      "total": Number(values.total),
      "parent_id": Number(values.parent_id),
      "role_ids": values.role_ids,
      "tg_id": values.tg_id,

  }

    if(item.id) {
      dispatch( updUser(data, item.id ));
    } else {
      dispatch( addUser(data));
    }
  };

  const {
    values,
    validateForm,
    handleChange,
    submitForm,
    handleBlur,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true
  });

  const handlerClickSubmit = async () => {
    const curErrors = await validateForm();
    const curErrorsKeys = Object.keys(curErrors);
    if (curErrorsKeys.length) {
      const el = document.getElementById(curErrorsKeys[0]);
      if (el) el.focus();
    }
    submitForm();
  };

  const close = () => {
    dispatch(modalUser(false));
    dispatch(clearUser());
    resetForm();
  };

  const handleChangeSelect = (name: string, value: any) => {
    setFieldValue(name, value.value)
  }

  const handleChangeRoles = (name: string, value: any) => {
    const rolesId = value.map((item: any) => item.value)
    setFieldValue(name, rolesId)
  }

  const handleChangeTgUserId =(name: string, value:any) =>{
    const id = value?.value;

    setFieldValue(name, id);
  }

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        {item?.id  ? "Edit" : "Add"} User
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <LabelInput
                  label="Name*"
                  placeholder="Name*"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ""}
                  hasError={!!(errors.name && touched.name)}
                  errorText={errors.name}
                />
              </div>
              <div className="mb-3">
                <LabelInput
                  label="email*"
                  placeholder="email*"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email || ""}
                  hasError={!!(errors.email && touched.email)}
                  errorText={errors.email}
                />
              </div>
              <div className="mb-3">
                <LabelInput
                  label="buyer ai*"
                  placeholder="buyer_ai*"
                  name="buyer_ai"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.buyer_ai || ""}
                  hasError={!!(errors.buyer_ai && touched.buyer_ai)}
                  errorText={errors.buyer_ai}
                />
              </div>
              <div className="mb-3">
                <LabelInput
                  label="password"
                  placeholder= {item.id ? "password": "password*"}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  hasError={!!(errors.password && touched.password)}
                  errorText={errors.password}
                />
              </div>
              <div className="mb-3">
                <LabelInput
                  label="total"
                  placeholder="total"
                  name="total"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.total || ""}
                  hasError={!!(errors.total && touched.total)}
                  errorText={errors.total}
                />
              </div>
                  <div className='mb-3'>
                <Label>Team: </Label>
                <Select
                    isSearchable
                    name="team_id"
                    label="Team"
                    placeholder="Team"
                    options={teamsData}
                    onChange={(value: any) => handleChangeSelect("team_id", value)}
                    value={teamsData.find((item: any) => item.value === values?.team_id) }
                />
              </div>
              <div className='mb-3'>
                <Label>Parent: </Label>
                <Select
                    isSearchable
                    label="Parent"
                    name="parent_id"
                    placeholder="Parent"
                    options={resUsers}
                    onChange={(value: any) => handleChangeSelect("parent_id", value)}
                    value={resUsers.find((item: any) => item.value === values?.parent_id) }
                />
              </div>
              {rolData &&
                  <div className='mb-3'>
                    <Label>Roles:</Label>
                    <Select
                        isMulti
                        isSearchable
                        label="Roles"
                        placeholder="Roles"
                        options={rolData}
                        onChange={(value: any) => handleChangeRoles("role_ids", value)}
                        defaultValue={rolData.filter((item: any) => {
                          return values?.role_ids.includes(item.value);
                        })}
                    />
                  </div>
              }
                  <div className='mb-3'>
                <Label>Telegram: </Label>
                <Select
                    isSearchable
                    name="Telegram"
                    label="Telegram"
                    placeholder="Telegram"
                    options={tgUserId}
                    onChange={(value: any) => handleChangeTgUserId("tg_id", value)}
                    value={tgUserId.find((item: any) => item.tg_id === values.tg_id) }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={handleSubmitForm}
                  disabled={!isValid}
                > Save </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalTeam;