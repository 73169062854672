import { KeitatosTypes } from "./actionTypes"

export const getKeitatos = () => ({
    type: KeitatosTypes.GET_KEITAROS,
  })

  export const getKeitatosSuccess = (payload : any) => ({
    type: KeitatosTypes.GET_KEITAROS_SUCCESS,
    payload,
  })

export const getKeitatosFailure = (payload : any) => ({
    type: KeitatosTypes.GET_KEITAROS_FAILURE,
    payload
})

export const getKeitatosAll = () => ({
  type: KeitatosTypes.GET_KEITAROS_ALL,
})

export const getKeitatosAllSuccess = (payload : any) => ({
  type: KeitatosTypes.GET_KEITAROS_ALL_SUCCESS,
  payload,
})

export const clearKeitatosAll = () => ({
  type: KeitatosTypes.CLEAR_KEITAROS_ALL,
})

export const getKeitato = (id: number) => ({
  type: KeitatosTypes.GET_KEITARO,
  id
})

export const getKeitatoSuccess = (payload : any) => ({
  type: KeitatosTypes.GET_KEITARO_SUCCESS,
  payload,
})

export const getKeitatoFailure = (payload : any) => ({
  type: KeitatosTypes.GET_KEITARO_FAILURE,
  payload
})

export const addKeitatos = (data: any) => ({
  type: KeitatosTypes.ADD_KEITAROS,
  data
})

export const addKeitatosSuccess = (data: any) => ({
  type: KeitatosTypes.ADD_KEITAROS_SUCCESS,
  payload: data
})

export const addKeitatosFail = (error : any) => ({
  type: KeitatosTypes.KEITAROS_FAILURE,
  payload: error,
})
//
export const updKeitatos = (data : any, id: number) => ({
  type: KeitatosTypes.UPD_KEITAROS,
  id, data
})

export const updKeitatosSuccess = (data: any) => ({
  type: KeitatosTypes.UPD_KEITAROS_SUCCESS,
  payload: data
})
//
export const updKeitatosFail = (error : any) => ({
  type: KeitatosTypes.KEITAROS_FAILURE,
  payload: error,
})

export const delKeitatos = (id: number) => ({
  type: KeitatosTypes.DEL_KEITAROS,
  id
})

export const delKeitatosSuccess = (id: number) => ({
  type: KeitatosTypes.DEL_KEITAROS_SUCCESS,
  payload: id
})

export const delKeitatosFail = (error : any) => ({
  type: KeitatosTypes.KEITAROS_FAILURE,
  payload: error,
})
//
export const modalKeitatos = (status: boolean) => ({
  type: KeitatosTypes.KEITAROS_MODAL,
  status
})

export const getKeitatosStat = (id: number) => ({
  type: KeitatosTypes.GET_KEITAROS_STAT,
  id
})

export const getKeitatosStatSuccess = (payload : any) => ({
  type: KeitatosTypes.GET_KEITAROS_STAT_SUCCESS,
  payload,
})

export const getKeitatosStatFailure = (payload : any) => ({
  type: KeitatosTypes.GET_KEITAROS_STAT_FAILURE,
  payload
})

export const clearKeitatos = () => ({
  type: KeitatosTypes.CLEAR_KEITAROS,
})