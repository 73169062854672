import { call, put, takeEvery } from "redux-saga/effects"
import { UserTypes } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserSuccess,
  getUserFail,
  modalUser,
  addUserSuccess,
  addUserFail,
  updUserFail,
  updUserSuccess,
  delUserSuccess,
  delUserFail, getUsersAllSuccess,
} from "./actions"

import {
  getUsers as getUsersApi,
  getUser as getUserApi,
  postUser as postUserApi,
  updateUser as updateUserApi,
  delUser as delUserApi,
  getUsersAll as getUsersAllApi,
} from "../../helpers/backend_helper"

function* fetchUsers({next_page, filter}: any) {
  try {
    const response : Promise<any> = yield call(getUsersApi, next_page, filter)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsersAll() {
  try {
    const response : Promise<any> = yield call(getUsersAllApi)
    yield put(getUsersAllSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUser({id}: any ) {
  try {
    const response: Promise<any> = yield call(getUserApi, id);
    yield put(getUserSuccess(response));
    yield put(modalUser(true))
  } catch (error) {
    yield put(getUserFail(error));
  }
}

function* addUserSaga({data}: any) {
  try {
    const response: Promise<any> = yield call(postUserApi, data);
    yield put(addUserSuccess(response));
    yield put(modalUser(false))
  } catch (error) {
    yield put(addUserFail(error));
  }
}

function* updateUserSaga({data, id} : any) {
  try{
    const response : Promise<any> = yield call(updateUserApi, data, id);
    yield put(updUserSuccess(response));
    yield put(modalUser(false))
  }catch (error) {
    yield put(updUserFail(error))
  }
}

function* delUserSaga({id} : any) {
  try{
    yield call(delUserApi, id);
    yield put(delUserSuccess(id));
  }catch (error) {
    yield put(delUserFail(error))
  }
}


function* teamsSaga() {
  yield takeEvery(UserTypes.GET_USERS, fetchUsers)
  yield takeEvery(UserTypes.GET_USERS_ALL, fetchUsersAll)
  yield takeEvery(UserTypes.GET_USER, fetchUser)
  yield takeEvery(UserTypes.ADD_USER, addUserSaga)
  yield takeEvery(UserTypes.UPD_USER, updateUserSaga)
  yield takeEvery(UserTypes.DEL_USER, delUserSaga)
}

export default teamsSaga;