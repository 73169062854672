import { error } from "console";
import {  LeadDashboardTypes } from "./actionTypes";

export const getKt = (date:any) =>({
  type: LeadDashboardTypes.GET_KT,
  date
})

export const getKtSuccess = (payload: any) =>({
  type: LeadDashboardTypes.GET_KT_SUCCESS,
  payload,
})


export const getKtFailure =(payload: any) =>({
  type:LeadDashboardTypes.GET_KT_FAILURE,
  payload,
})

export const postLead =(date: any)=>({
  type:LeadDashboardTypes.POST_LEADS,
  date,
})

export const postLeadSucces =(payload: any)=>({
  type:LeadDashboardTypes.POST_LEADS_SUCCES,
  payload
})

export const postLeadFailure=(error: any) =>({
  type:LeadDashboardTypes.POST_LEADS_FAILURE,
  payload: error,
})


export const modalLead = (status: boolean) => ({ 
  type:LeadDashboardTypes.MODAL_LEADS,
  status
})