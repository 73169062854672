import {TeamTypes} from "./actionTypes"

export const getTeams = (next_page: string, filter: object) => ({
  type: TeamTypes.GET_TEAMS,
  next_page, filter
})

export const getTeamsSuccess = (data : any) => ({
  type: TeamTypes.GET_TEAMS_SUCCESS,
  payload: data,
})

export const getTeamsFail = (error : any) => ({
  type: TeamTypes.TEAM_FAIL,
  payload: error,
})

export const getTeamsAll = () => ({
  type: TeamTypes.GET_TEAMS_ALL
})

export const getTeamsAllSuccess = (data : any) => ({
  type: TeamTypes.GET_TEAMS_ALL_SUCCESS,
  payload: data,
})

export const clearTeamsAll = () => ({
  type: TeamTypes.CLEAR_TEAMS_ALL,
})


export const clearTeams = () => ({
    type: TeamTypes.CLEAR_TEAMS,
})

export const getTeam = (id: number) => ({
  type: TeamTypes.GET_TEAM,
  id
})

export const getTeamSuccess = (data : any) => ({
  type: TeamTypes.GET_TEAM_SUCCESS,
  payload: data,
})

export const getTeamFail = (error : any) => ({
  type: TeamTypes.TEAM_FAIL,
  payload: error,
})

export const clearTeam = () => ({
  type: TeamTypes.CLEAR_TEAM,
})

export const modalTeam = (status: boolean) => ({
  type: TeamTypes.MODAL_TEAM,
  status
})

export const addTeam = (data: any) => ({
  type: TeamTypes.ADD_TEAM,
  data
})

export const addTeamSuccess = (data: any) => ({
  type: TeamTypes.ADD_TEAM_SUCCESS,
  payload: data
})

export const addTeamFail = (error : any) => ({
  type: TeamTypes.TEAM_FAIL,
  payload: error,
})

export const updTeam = (data : any, id: number) => ({
  type: TeamTypes.UPD_TEAM,
  id, data
})

export const updTeamSuccess = (data: any) => ({
  type: TeamTypes.UPD_TEAM_SUCCESS,
  payload: data
})

export const updTeamFail = (error : any) => ({
  type: TeamTypes.TEAM_FAIL,
  payload: error,
})

export const delTeam = (id: number) => ({
  type: TeamTypes.DEL_TEAM,
  id
})

export const delTeamSuccess = (id: number) => ({
  type: TeamTypes.DEL_TEAM_SUCCESS,
  payload: id
})

export const delTeamFail = (error : any) => ({
  type: TeamTypes.TEAM_FAIL,
  payload: error,
})

export const getTeamMembers = (id: number) =>({
  type: TeamTypes.GET_TEAM_MEMBERS,
  id
})

export const getTeamMembersSuccess = (data : any) =>({
  type: TeamTypes.GET_TEAM_MEMBERS_SUCCESS,
  payload: data,
})
