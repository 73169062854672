import {KeitatosTypes, CheckerState} from "./actionTypes"

export const INIT_STATE: CheckerState = {
    data: [],
    kio_list: [],
    item: {},
    stat: {},
    loading: false,
    error : "",
    isOpenModal: false,
    loaded: false
  }

 const checker = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case KeitatosTypes.GET_KEITAROS:
        return {
          ...state,
          loading: true
        }

        case KeitatosTypes.GET_KEITAROS_SUCCESS:
          return {
            ...state,
            data: action.payload.data,
            loaded: true,
            loading: false
          }

        case KeitatosTypes.GET_KEITAROS_ALL:
            return {
                ...state,
            }

        case KeitatosTypes.GET_KEITAROS_ALL_SUCCESS:
            return {
                ...state,
                kio_list: action.payload,
            }

        case KeitatosTypes.CLEAR_KEITAROS_ALL:
            return {
                ...state,
                kio_list: [],
                error: {},
            }

        case KeitatosTypes.GET_KEITARO_SUCCESS:
            return {
                ...state,
                item: action.payload,
            }

        case KeitatosTypes.GET_KEITAROS_STAT_SUCCESS:
            return {
                ...state,
                stat: action.payload,
            }

          case KeitatosTypes.CLEAR_KEITAROS:
            return {
              ...state,
              item: {},
              error: {},
            }
        case KeitatosTypes.ADD_KEITAROS_SUCCESS:
              return {
                ...state,
                data: [...state.data, action.payload],
              }
        case KeitatosTypes.UPD_KEITAROS_SUCCESS:
                const items = state.data.map((item : any) => {
                  return (item.id === action.payload.id) ? action.payload : item
                })
                return {
                  ...state,
                  data: items,
                  error: {},
                  loading: false,
                  loaded: true
                }  
        case KeitatosTypes.DEL_KEITAROS_SUCCESS:
                  return {
                    ...state,
                    data: state.data.filter((item : any) => item.id.toString() !== action.payload.toString() ),
                    error: {},
                    loading: false,
                    loaded: true
                  }
        case KeitatosTypes.KEITAROS_FAILURE:
                    return {
                      ...state,
                      error: action.payload,
                      loaded: false,
                      loading: false
                    }      
        case KeitatosTypes.KEITAROS_MODAL:
                return {
                  ...state,
                  isOpenModal: action.status
                }
        default:
        return state;
    }
  }

  export default checker;