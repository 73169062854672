import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik} from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, CardHeader, Col, Container, Label, Row, Form, Input,} from "reactstrap";
import Select from "../../components/Common/Select";
import {
    clearKeitaros,
    clearKeitarosCampaigns,
    getKeitaros,
    getKeitarosCampaigns,
    submitChangeDomain
} from "../../store/domainchg/actions";

interface SelectedItem {
    id: string;
    url: string;
}

interface FormValues {
    selectedBlack: SelectedItem | null;
    selectedWhite: SelectedItem | null;
    domainTo: string;
}

const validationSchema = Yup.object().shape({
    domainTo: Yup
    .string()
    .matches(
      /^(https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/,
      "Please enter a valid domain"
    )
    .required("Domain is required"),
    selectedBlack: Yup.object<SelectedItem>().nullable().required("Black selection is required"),
    selectedWhite: Yup.object<SelectedItem>().nullable().required("White selection is required"),
});

export const TRow = ({ data, onSelect, name }: { data: any, onSelect: (selected: any) => void, name: string }) => {
    return (
        <div className="d-flex border bg-light mb-3" key={data.id}>
            <div className="p-2 border">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={`${name}-${data.id}`}
                    onChange={() => onSelect(data)}
                />
            </div>
            <div className="p-2 border">{data.name}</div>
            <div className="p-2 border">{data.url}</div>
        </div>
    );
};

const KioDomainChanger = () => {
    const [selectedKetaros, setSelectedKeitaros] = useState<any>(null);
    const dispatch = useDispatch();
    const { keitaros, campaigns, loadedChangeDomain } = useSelector((state: any) => state.Domainchg);
    const { bk_camps, wk_camps } = campaigns || { bk_camps: [], wk_camps: [] };

    useEffect(() => {
        dispatch(getKeitaros());
        return () => {
            dispatch(clearKeitaros());
            dispatch(clearKeitarosCampaigns());
        };
    }, []);

    const resKeitaros = keitaros.map((item: any) => ({
        value: item.id,
        label: item.ip,
    }));

    useEffect(() => {
        if (selectedKetaros) {
            dispatch(getKeitarosCampaigns(selectedKetaros.value));
        }
    }, [selectedKetaros]);

    useEffect(() => {
        if(loadedChangeDomain){
            dispatch(getKeitarosCampaigns(selectedKetaros.value));
            resetForm();
        }
    }, [loadedChangeDomain]);

    const handleSubmitForm = (values: any) => {
        const finalObject = {
            bk_id: selectedKetaros.value,
            bk_comp_id: values.selectedBlack.id,
            wk_comp_id: values.selectedWhite.id,
            domain_from_black: values.selectedBlack.url,
            domain_from_white: values.selectedWhite.url,
            domain_to: values.domainTo,
        };
        dispatch(submitChangeDomain(finalObject));
    };

    const {
        values,
        handleSubmit,
        resetForm,
        isValid,
        setFieldValue,
        touched,
        handleBlur,
        errors,
    } = useFormik<FormValues>({
        initialValues: {
            selectedBlack: null,
            selectedWhite: null,
            domainTo: "",
        },
        onSubmit: handleSubmitForm,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <div className="page-content" style={{ minHeight: 450 }}>
            <Container fluid>
                <Breadcrumbs title="Dashboard" breadcrumbItem="Keitaro Domain Changer" />

                <Row>
                    <Col className="justify-content-end">
                        <div className="align-items-center justify-content-end mb-3">
                            <Label>Select keitaro</Label>
                            <Select
                                isSearchable
                                placeholder="Select keitaros"
                                options={resKeitaros}
                                onChange={(selectedOption: any) => setSelectedKeitaros(selectedOption)}
                                value={selectedKetaros}
                                isClearable={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Form className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                        {wk_camps.length > 0 &&
                            <Col xl={6} md={6}>
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title mb-0">White</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {wk_camps.map((i: any) => (
                                                <TRow
                                                    key={i.id}
                                                    data={i}
                                                    onSelect={(data) => setFieldValue("selectedWhite", data)}
                                                    name="white"
                                                />
                                            ))}
                                        </Row>
                                        {errors.selectedWhite && (
                                            <div className="text-danger">{errors.selectedWhite}</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        }
                        {bk_camps.length > 0 &&
                            <Col xl={6} md={6}>
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title mb-0">Black</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {bk_camps.map((i: any) => (
                                                <TRow
                                                    key={i.id}
                                                    data={i}
                                                    onSelect={(data) => setFieldValue("selectedBlack", data)}
                                                    name="black"
                                                />
                                            ))}
                                        </Row>
                                        {errors.selectedBlack && (
                                            <div className="text-danger">{errors.selectedBlack}</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        }
                    </Row>
                    {(values.selectedBlack && values.selectedWhite) && (
                        <Row className="mt-4">
                            <Col>
                                <div className="hstack gap-3">
                                    <Input
                                        onChange={(e) => setFieldValue("domainTo",e.target.value)}
                                        className="form-control me-auto"
                                        type="url"
                                        placeholder="https://google.com"
                                        name="domainTo"
                                        onBlur={handleBlur}
                                    />
                                    {touched.domainTo && errors.domainTo && (
                                        <div className="text-danger">{errors.domainTo}</div>
                                    )}

                                    <button type="submit" className="btn btn-success" disabled={!isValid && !loadedChangeDomain}>
                                        Submit
                                    </button>
                                    <div className="vr"></div>
                                    <button type="reset" className="btn btn-outline-danger">
                                        Reset
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Form>

            </Container>
        </div>
    );
};

export default KioDomainChanger;