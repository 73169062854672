export const POST_JWT_LOGIN = "/login"
export const GET_TEAMS = "/teams"
export const GET_JIRA_PROJECTS = "/jira/projects"
export const GET_JIRA_USERS = "/jira/users"
export const GET_JIRA_STATISTICS = "/jira/user-tasks"
export const GET_USERS = "/users"
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const GET_CHECKIN = "statistic/checkin/"
export const GET_BROKERS = "/brokers"
export const GET_SPENDS = "/spends"
export const GET_PRICE_DAY ='/broker-price-days'

export const GET_PERMISSIONS = '/permissions'
export const GET_ROLES = '/roles'

export const GET_STATISTIC = '/statistic'

export const GET_TEAMS_ALL = '/teams/all'
export const GET_USERS_ALL = '/users/all'

export const GET_ORDERS = '/orders'

export const GET_TEAM_MEMBERS = `/teams/`


export const GET_TRACKER = `/stat/getcapa`
export const POST_CAPA = `/stat/setcapa`
export const POST_ADD_USER_CHEKIN = `/accounts` 
export const GET_UPDATE_BROKER_NAME =`/stat/setbrokers/53`

export const GET_CHECKER = `/dns`
export const GET_CHECKER_ALL = `/dns/all`

export const GET_KEITAROS = `/uni/keitaros`

export const POST_UNI_KEITAROS = '/uni/offerunicalize'

export const GET_KEITARO_DATA = '/leads/log-lead'
export const POST_OFFER_LEADS ='/leads/get-offer-leads'

export const GET_DOMAINCHG = "/domainchg"