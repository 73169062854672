import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, Col, Container, Row} from "reactstrap";
import axios from "axios";

const StatisticBuyer = () => {

    const [caps, setCaps] = useState({})

    useEffect(() => {
        const datefrom: string = new Date().toISOString().slice(0, 10) + ' 00:00:00';
        const tomorrow: Date = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dateto: string = tomorrow.toISOString().slice(0, 10) + ' 23:59:59';

        const send_data = {
            from: datefrom,
            to: dateto,
            type: "3",
            page: "0"
        };

        const send_lead = async (lead_data: any) => {
            const headers: Record<string, string> = {
                'Content-Type': 'application/json',
                'x-trackbox-username': 'tbapi',
                'x-trackbox-password': 'da81fax21gffwD',
                'x-api-key': '264388973aaa9b2f9eb2aa84a9c7382e',
            };

            try {
                const response = await axios.post('https://trx.project-x.company/api/pull/customers', lead_data, {
                    headers: headers
                });
                return response.data;
            } catch (error) {
                console.error('Error:', error);
                throw new Error('Network response was not ok');
            }
        };

        const sendLeadResponse = async () => {
            try {
                const response = await send_lead(send_data);
                console.log(response);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        sendLeadResponse();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Statistic Buyer" />

                    <Row>
                        <Col lg="12">
                            <Card>

                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default StatisticBuyer;
