import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "../../components/Common/Select";
import NoSearchResult from "../../components/Common/NoSearchResult";
import TableLead from "./components/TableLead";
import ModalLead from "./components/ModalLead";

import { getKt, postLead,modalLead } from "src/store/leadDashboard/actions";
import { formatDate } from "src/helpers/formatDate";




const LeadDashboard = () => {
  const dispatch = useDispatch();
  const [fromDateStr, setFromDateStr] = useState<string>();
  const [toDateStr, setToDateStr] = useState<string>();
  const [selectedKt, setSelectedKt] = useState<any>(null);
  const [selectedOffer, setSelectOffer] = useState<any>(null);
  const [optionsSelectOffers, optionsSelectSetOffers] = useState<any>(null);
  const [response, setResponse ] = useState<string>();
  


  const ktDate = useSelector((state:any) => state.leadDashboard.kt || []);
  const dateLead = useSelector((state:any) => state.leadDashboard.leadData);
  const loading = useSelector((state: any) => state.leadDashboard.loading || false);
  const isOpenModal =useSelector((state:any) => state.leadDashboard.isOpenModal || false);

    const formatRangeDate = (dateRange: [Date | null, Date | null]): any => {
        const [fromDate, toDate] = dateRange;
        setFromDateStr(fromDate ? formatDate(fromDate) : "");
        setToDateStr(toDate ? formatDate(toDate) : "");
    };

  const getLead =()=> {
    const getlead={
        lead_date_start : fromDateStr,
        lead_date_end : toDateStr,
        k_ip : selectedKt.label,
        o_id : selectedOffer.label,
    }
    dispatch(postLead(getlead))
  }

  useEffect(() => {
    if (toDateStr) {
      const datefromTo={
        from: fromDateStr,
        to: toDateStr
      }
      dispatch(getKt(datefromTo))
    }
  }, [toDateStr]);
  return(
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Lead Dashboard" />

          <Row>
            <Col md={12}>
              <Card className="card-h-100">
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: 350 }}>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(date: any) => {
                          formatRangeDate(date);
                          
                        }}
                      />
                    </div>
                    <Select
                      isSearchable
                      placeholder="Pick up Keitaro"
                      disabled={!Boolean(fromDateStr) && !Boolean(toDateStr) && !!ktDate}
                      onChange={(selectedOption: any) =>{
                        setSelectedKt(selectedOption)
                        optionsSelectSetOffers(selectedOption.offers)
                      }
                      }
                      options={ktDate}
                      value={selectedKt}
                    />
                                        <Select
                      isSearchable
                      placeholder="Pick up Offer"
                      disabled={!optionsSelectOffers}
                      onChange={(selectedOption: any) =>
                      {
                        setSelectOffer(selectedOption)
                      }
                      }

                      options={optionsSelectOffers}

                      value={selectedOffer}
                    />
                    <Button
                    className="btn"
                    color="success"
                    disabled={!selectedOffer}
                    onClick={getLead}
                    >
                      Get leads
                      </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { dateLead.length > 0 && <TableLead data={dateLead} pageSize={10} setRes={setResponse}/> || <NoSearchResult  text="Enter the required parameters"/>}

                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isOpenModal && <ModalLead isOpen={isOpenModal}  islead={response}/>}
        </Container>
      </div>
    </>
  )
}



export default LeadDashboard;