import {UserTypes} from "./actionTypes"

export const fetchCheckin = () => ({
  type: 'FETCH_CHECKIN_REQUEST',
});

export const getUsers = (next_page: string, filter: object) => ({
  type: UserTypes.GET_USERS,
  next_page, filter
})

export const getUsersSuccess = (data : any) => ({
  type: UserTypes.GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = (error : any) => ({
  type: UserTypes.USER_FAIL,
  payload: error,
})

export const getUsersAll = () => ({
  type: UserTypes.GET_USERS_ALL
})

export const getUsersAllSuccess = (data : any) => ({
  type: UserTypes.GET_USERS_ALL_SUCCESS,
  payload: data,
})

export const clearUsersAll = () => ({
  type: UserTypes.CLEAR_USERS_ALL,
})

export const clearUsers = () => ({
    type: UserTypes.CLEAR_USERS,
})

export const getUser = (id: number) => ({
  type: UserTypes.GET_USER,
  id
})

export const getUserSuccess = (data : any) => ({
  type: UserTypes.GET_USER_SUCCESS,
  payload: data,
})

export const getUserFail = (error : any) => ({
  type: UserTypes.USER_FAIL,
  payload: error,
})

export const clearUser = () => ({
  type: UserTypes.CLEAR_USER,
})

export const modalUser = (status: boolean) => ({
  type: UserTypes.MODAL_USER,
  status
})

export const addUser = (data: any) => ({
  type: UserTypes.ADD_USER,
  data
})

export const addUserSuccess = (data: any) => ({
  type: UserTypes.ADD_USER_SUCCESS,
  payload: data
})

export const addUserFail = (error : any) => ({
  type: UserTypes.USER_FAIL,
  payload: error,
})

export const updUser = (data : any, id: number) => ({
  type: UserTypes.UPD_USER,
  id, data
})

export const updUserSuccess = (data: any) => ({
  type: UserTypes.UPD_USER_SUCCESS,
  payload: data
})

export const updUserFail = (error : any) => ({
  type: UserTypes.USER_FAIL,
  payload: error,
})

export const delUser = (id: number) => ({
  type: UserTypes.DEL_USER,
  id
})

export const delUserSuccess = (id: number) => ({
  type: UserTypes.DEL_USER_SUCCESS,
  payload: id
})

export const delUserFail = (error : any) => ({
  type: UserTypes.USER_FAIL,
  payload: error,
})