import React, {useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch} from "react-redux";
import { getPriceDay, delPriceDay} from "../../../store/price_day/actions";
import {getBrokersId} from "../../../store/brokers/actions";
import usePermissions from "src/hooks/usePermissions";

const TablePrice = React.memo((props: any) =>  {
    const { data, pageSize, } = props


    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();

    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getPriceDay(arg.id))
        dispatch(getBrokersId());
    };

    const brokers = useMemo(() =>
            data.map((item: any) => ({
                id: item.id.toString() || null,
                broker_id: item.broker_id  || null,
                price: item.price  || null,
                date: item.date  || null,
                name: item.broker?.name || "Plz add name"
            })).filter((item: any) => item !== null),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Broker ID",
                accessor: "broker_id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Price",
                accessor: "price",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Date",
                accessor: "date",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            { hasPermission("update-price-day") &&
                            <Link className="text-success" to="#" onClick={() => {
                                const priceDayData = cellProps.row.original;
                                onClickUpdate(priceDayData)
                            }}>
                                <i className="mdi mdi-pencil font-size-18" ></i>
                            </Link>
                            }
                            {
                            hasPermission("delete-price-day") &&
                            <Link className="text-danger" to="#">
                                <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => {
                                        const priceDay = cellProps.row.original;
                                        onClickDelete(priceDay);
                                    }}
                                ></i>
                            </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteBroker = () => {
        dispatch(delPriceDay(contact.id));
        setDeleteModal(false);
    };

    return (
        <>
            <TableContainer
                columns={columns}
                data={brokers}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteBroker}
                onCloseClick={() => setDeleteModal(false)}
            />}
        </>
    )
})
TablePrice.displayName = "TablePrice";
export default TablePrice