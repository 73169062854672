export enum LeadDashboardTypes{
  GET_KT="@@leadDashboard/GET_KT",
  GET_KT_SUCCESS="@@leadDashboard/GET_KT_SUCCESS",
  GET_KT_FAILURE="@@leadDashboard/GET_KT_FAILURE",
  POST_LEADS="@@leadDashboard/POST_LEADS",
  POST_LEADS_SUCCES="@@leadDashboard/POST_LEADS_SUCCES",
  POST_LEADS_FAILURE="@@leadDashboard/POST_LEADS_FAILURE",
  MODAL_LEADS="@@leadDashboard/MODAL_LEADS",
}

export interface LeadDashboardState{
  kt: Array<any>;
  leadData:Array<any>;
  loading : boolean;
  error : any;
  loaded: boolean,
  isOpenModal: boolean,
}