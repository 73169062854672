import PropTypes from "prop-types";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Progress,
  UncontrolledDropdown,
  Tooltip, UncontrolledTooltip, Row
} from "reactstrap";

const CardCap = (props: any) => {
  const { data, id } = props;

  let result;

  switch (data.flag) {
    case 1:
      result = "success";
      break;
    case 2:
      result = "warning";
      break;
    case 3:
      result = "danger";
      break;
    default:
      result = "info";
  }

  return (
    <React.Fragment>
      <Col xl="2" sm="3">
        <Card className="text-center">
          <CardBody>

            <div className="btn-group justify-content-between" style={{display: "flex"}} role="group">
              <div className={"text-start"}>
                <i className="bx bx-info-circle" id={id}></i>

                <UncontrolledTooltip
                    placement="bottom"
                    target={id}
                >
                  {data.boxes}
                </UncontrolledTooltip>
              </div>

              <UncontrolledDropdown className="dropdown text-end">
                <DropdownToggle className="text-muted font-size-16" tag="a">
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem to="#">Edit</DropdownItem>
                  <DropdownItem to="#">Action</DropdownItem>
                  <DropdownItem to="#">Remove</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <h5 className="font-size-12 mb-1">
              <Link to="#" className="text-body">
                {data.brokerName}
              </Link>
            </h5>
            
            <>
              <div style={{ position: 'relative', width: '100%' }}>
                <Progress striped max={data?.capatoday || 0} value={data.capa} className="my-1" color={result} style={{ height: '20px', backgroundColor: '#B9BBB6' }} >
                <span style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  lineHeight: '20px'
                }}>
                  {data.capa}/{data?.capatoday|| 0}
                </span>

                </Progress>
              </div>
            </>

            <div className={"font-size-10"}>
              {data.Offers}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardCap.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string
};

export default CardCap;
