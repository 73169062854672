import { combineReducers } from "redux"



import Layout from "./layout/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import Teams from "./team/reducer";
import Users from "./user/reducer";
import Brokers from "./brokers/reducer";
import Checkin from "./checkin/reducer";
import Spend from "./spends/reducer";
import permissions from "./permissions/reducer";
import roles from "./roles/reducer";
import priceDay from "./price_day/reducer";
import statistic from "./statistic/reducer";
import dashboard from "./dashboard/reducer";
import orders from "./orders/reducer";
import traker from "./tracker/reducer"
import jira from "./jira/reducer"
import keitarosReducer from "./uni/reducer";
import checker from "./checker/reducer";
import leadDashboard from "./leadDashboard/reducer";
import Domainchg from "./domainchg/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  login,
  profile,
  Teams,
  Users,
  Brokers,
  Checkin,
  Spend,
  priceDay,
  permissions,
  roles,
  statistic,
  dashboard,
  orders,
  traker,
  jira,
  keitarosReducer,
  checker,
  leadDashboard,
  Domainchg
})

export default rootReducer
