import React, {useEffect, useMemo, useState} from "react";
import {Form, ModalBody, ModalHeader, Modal, Row, Col, Label} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";
import {
  modalKeitatos,
  updKeitatos,
  addKeitatos,
  clearKeitatos,
  getKeitatos,
  getKeitatosAll, clearKeitatosAll
} from "../../../store/checker/actions";
import MySelect from "../../../components/Common/Select";
import {clearTeamsAll, getTeamsAll} from "../../../store/team/actions";
import {co} from "@fullcalendar/core/internal-common";
import {clearUsersAll, getUsersAll} from "../../../store/user/actions";
import Select from "../../../components/Common/Select";

interface Props {
  isOpen: boolean;
}

interface OptionType {
  value: string;
  label: string;
}

interface IFormValues {
  type: number;
  ip: string;
  key: string;
  short_name: string;
  "parent_id": null,
  "team_id": null,
  "users": any[]
}

const validationSchema = yup.object().shape({
  type: yup.number().required("required"),
  key: yup.string().required("required"),
  ip: yup.string().required("required"),
  short_name: yup.string().required("required"),
  parent_id: yup.number().nullable(),
  team_id: yup.number().nullable(),
  users: yup.array().of(yup.string()).required("required")
});

const TYPE = [
  {label: "Black", value: 1},
  {label: "White", value: 2}
];

const ModalRoles: React.FC<Props> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const {item, stat, kio_list}  = useSelector((state: any) => state.checker);
  const {members: teamMembers} = useSelector((state: any) => state.Teams)
  const {members: userMembers} = useSelector((state: any) => state.Users)

  useEffect(() => {
    dispatch(getTeamsAll());
    dispatch(getUsersAll());
    dispatch(getKeitatosAll());
    return () => {
      dispatch(clearTeamsAll())
      dispatch(clearUsersAll())
      dispatch(clearKeitatosAll())
    }
  }, []);


  const initialValues: IFormValues = useMemo(() => {
    return {
      type: item?.type || null,
      ip: item?.ip || "",
      key: item?.key || "",
      short_name: item?.short_name || "",
      parent_id: item?.parent_id || null,
      team_id: item?.team_id || null,
      users: item?.users || [],
    };
  }, [item]);

  const handleSubmitForm = (values: IFormValues) => {
    (item?.id)
        ? dispatch(updKeitatos(values, item?.id))
        : dispatch(addKeitatos(values));
  };


  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
    isValid,
    setFieldValue
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const close = () => {
    dispatch(modalKeitatos(false));
    dispatch(clearKeitatos());
    resetForm();
  };

  return (
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close} tag="h4">
          {item?.id ? "Edit" : "Add"} keitaro
        </ModalHeader>
        <ModalHeader  tag="h4">
          {stat?.id && (
              <>
                <span> checked {stat.checked}</span>
                <span> notchecked {stat.notchecked.toString()}</span>
              </>
          )}
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              {teamMembers.length > 0 && <Col xs={12} className="mb-4">
                <Label>Teams</Label>
                <Select
                    options={teamMembers}
                    onChange={(item: any) => setFieldValue("team",  item ? item.value : null)}
                    value={teamMembers.find((item: any) => item.value === values.team_id)}
                    isClearable={true}
                />
              </Col>}
              {userMembers && <Col xs={12} className="mb-4">
                <Label>Users *</Label>
                <Select
                    isSearchable
                    placeholder="Select Users"
                    options={userMembers}
                    onChange={(selectedOptions: OptionType[] | []) => {
                      const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                      setFieldValue("users", selectedValues);
                    }}
                    value={userMembers.filter((option: OptionType) => values?.users.includes(option.value))}
                    isMulti
                />
              </Col>}
              <Col xs={12} className="mb-4">
                <Label>Type keitaro *</Label>
                <Select
                    options={TYPE}
                    onChange={(item: any) => setFieldValue("type", item ? item.value : null)}
                    value={TYPE.find((type) => type.value === values.type)}
                    isClearable={true}
                />
              </Col>

              <Col xs={12} className="mb-4">
                <Label>Parent keitaro </Label>
                <Select
                    options={kio_list}
                    onChange={(item: any) => setFieldValue("parent_id", item ? item.value : null)}
                    value={kio_list.find((i:any) => i.value === values.parent_id)}
                    isClearable={true}
                />
              </Col>

              <Col xs={12}>
                <div className="mb-3">
                  <LabelInput
                      label="Key*"
                      placeholder="Key*"
                      name="key"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.key}
                      hasError={!!(errors.key && touched.key)}
                      errorText={errors.key}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mb-3">
                  <LabelInput
                      label="IP*"
                      placeholder="IP*"
                      name="ip"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ip}
                      hasError={!!(errors.ip && touched.ip)}
                      errorText={errors.ip}
                  />
                </div>
              </Col>

              <Col xs={12}>
                <div className="mb-3">
                  <LabelInput
                      label="Short name*"
                      placeholder="Short Name*"
                      name="short_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.short_name}
                      hasError={!!(errors.short_name && touched.short_name)}
                      errorText={errors.short_name}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user" disabled={!isValid}>
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
  );
};



export default ModalRoles;
