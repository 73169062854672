import { call, put, takeEvery } from "redux-saga/effects";
import {  getKtFailure, getKtSuccess, postLeadFailure, postLeadSucces } from "./actions";

import { getKeitaroDate as getKeitaroDateApi } from "../../helpers/backend_helper";
import { postLeads as postLeadsApi } from "../../helpers/backend_helper";
import { LeadDashboardTypes } from "./actionTypes";

function* fetchLeadDashboard(date: any) {

    try {

      const response: Promise<any> = yield call(getKeitaroDateApi, date.date);

      yield put(getKtSuccess(response));
    } catch (error) {
      yield put(getKtFailure(error));
    }
  }

function* postLeadDashboard({date}: any){
  try {
    const response: Promise<any> = yield call(postLeadsApi, date);
    yield put(postLeadSucces(response));
  } catch (error) {
    yield put(postLeadFailure(error));
  }
}


  function* leadDashboardSaga() {
    yield takeEvery(LeadDashboardTypes.GET_KT, fetchLeadDashboard)
    yield takeEvery(LeadDashboardTypes.POST_LEADS, postLeadDashboard)
  }

  export default leadDashboardSaga;