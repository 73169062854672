import React, {useEffect, useMemo} from "react";
import {Col, Form, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import LabelInput from "../../../components/Common/LabelInput";
import {addOrders, clearOrders, modalOrders, updOrders} from "src/store/actions";
import Flatpickr from "react-flatpickr";
import {formatDate} from "src/helpers/formatDate";
import Select from "../../../components/Common/Select";
import {OrderStatusEnum} from "../../../enums/OrderStatusEnum";
import {OrderStatus} from "../../../common/utils/model"

interface Props {
    isOpen: boolean;
}

interface IFormValues {
    name: string;
    price: string;
    team_id: any;
    to: string;
    from: string;
    type: number;
}

const validationSchema = yup.object().shape({
    name: yup.string().required("required"),
    price: yup.number().required("required"),
    team_id: yup.number().required("required"),
    to: yup.string().required("required"),
    from: yup.string().required("required"),
    type: yup.number().required("required"),
});


const ModalOrder: React.FC<Props> = ({isOpen}: Props) => {
    const dispatch = useDispatch();

    const {item} = useSelector((state: any) => state.orders);
    const teams = useSelector((state: any) => state.Teams.data);

    const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
    const isTeamLead = authUser.roles.some((role: any) => role.name === "teamlead");
    const userTeamId = isTeamLead ? authUser.team_id : null;

    const filteredTeams = useMemo(() => (isTeamLead ? teams.filter((item: any) => item.id === userTeamId) : teams), [teams, isTeamLead, userTeamId]);

    const teamsData = useMemo(() => (filteredTeams.map((item: any) => ({
        value: item.id,
        label: item.name
    }))), [filteredTeams]);

    const initialValues: IFormValues = useMemo(() => {
        return {
            name: item.name || "",
            price: item?.price || "",
            team_id: item.team_id || "",
            to: item.to || "",
            from: item.from || "",
            type: item.type || 1,
        };
    }, [item]);

    const handleSubmitForm = () => {
        const data = {
            "name": values.name,
            "price": Number(values.price),
            "team_id": values.team_id,
            "to": formatDate(values.to),
            "from": formatDate(values.from),
            "type": Number(values.type)
        }

        if (item.id) {
            data.type = Number(values.type);
            dispatch(updOrders(data, item?.id));
        } else {
            dispatch(addOrders(data));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(clearOrders());
        };
    }, [])

    const close = () => {
        dispatch(modalOrders(false));
        resetForm();
    };


    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        resetForm,
        isValid
    } = useFormik({
        initialValues,
        onSubmit: handleSubmitForm,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    const handleChangeSelect = (name: string, value: any) => {
        setFieldValue(name, value.value)

    }

    return (
        <Modal isOpen={isOpen} toggle={close}>
            <ModalHeader toggle={close} tag="h4">
                {item?.id ? "Edit" : "Add"} Order
            </ModalHeader>
            <ModalBody>
                <Form className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <LabelInput
                                    label="Name*"
                                    placeholder="Name*"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    hasError={!!(errors.name && touched.name)}
                                    errorText={errors.name}
                                />
                            </div>
                            <div className="mb-3">
                                <LabelInput
                                    label="Price*"
                                    placeholder="Price*"
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price}
                                    type="number"
                                    hasError={!!(errors.price && touched.price)}
                                    errorText={errors.price}
                                />
                            </div>

                            <div className="mb-3">
                                <Label>Team: </Label>
                                <Select
                                    isSearchable
                                    name="team_id"
                                    label="Team"
                                    placeholder="Team"
                                    options={teamsData}
                                    onChange={(value: any) => handleChangeSelect("team_id", value)}
                                    value={teamsData.find((item: any) => item.value === values?.team_id)}
                                />
                            </div>

                            {item.id > 0 && <div className="mb-3">
                                <Label>Order Status: </Label>
                                <Select
                                    value={OrderStatus.find((item: any) => item.value === values.type)}
                                    name="type"
                                    onChange={(value: any) => handleChangeSelect('type', value)}
                                    onBlur={handleBlur}
                                    label="Order type"
                                    hasError={!!(errors.type && touched.type)}
                                    errorText={errors.type}
                                    options={OrderStatus}
                                    isDisabled={values.type === 3}
                                />
                            </div>}

                            <div className="mb-3">
                                <Label>Date range: </Label>
                                <Flatpickr
                                    className="form-control d-block"
                                    label="to*"
                                    name="to"
                                    placeholder="dd M,yyyy"
                                    value={[values.from, values.to]}
                                    options={{
                                        mode: "range",
                                        dateFormat: "Y-m-d"
                                    }}
                                    onChange={(date: any) => {
                                        setFieldValue('from', date[0]);
                                        setFieldValue('to', date[1]);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="button"
                                    className="btn btn-success save-user"
                                    onClick={handleSubmitForm}
                                    disabled={!isValid}
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default ModalOrder;
