import { call, put, takeEvery } from "redux-saga/effects"
import { KeitatosTypes } from "./actionTypes"
import {
    addKeitatosFail,
    addKeitatosSuccess, delKeitatosFail, delKeitatosSuccess, getKeitatoFailure, getKeitatosAllSuccess,
    getKeitatosFailure, getKeitatosStatFailure, getKeitatosStatSuccess,
    getKeitatosSuccess, getKeitatoSuccess, modalKeitatos, updKeitatosFail, updKeitatosSuccess,

} from "./actions";
import {
    getCheckers as getCheckersApi,
    getCheckersAll as getCheckersAllApi,
    postChecker as postCheckerApi,
    updateChecker as updateCheckerApi,
    getChecker as getCheckerApi,
    delChecker as delCheckerApi,
    getCheckerStat as getCheckerStatApi,
} from "../../helpers/backend_helper"


function* fetchKts() {
    try {
      const response: Promise<any> = yield call(getCheckersApi);
      yield put(getKeitatosSuccess(response));
    } catch (error) {
      yield put(getKeitatosFailure(error));
    }
  }

function* fetchKtsAll() {
    try {
        const response: Promise<any> = yield call(getCheckersAllApi);
        yield put(getKeitatosAllSuccess(response));
    } catch (error) {
        yield put(getKeitatosFailure(error));
    }
}

  function* fetchKt({id}: any ) {
    try {
      const response: Promise<any> = yield call(getCheckerApi, id);
      yield put(getKeitatoSuccess(response));
    } catch (error) {
      yield put(getKeitatoFailure(error));
    }
  }

function* fetchKtStat({id}: any ) {
    try {
        const response: Promise<any> = yield call(getCheckerStatApi, id);
        yield put(getKeitatosStatSuccess(response));
    } catch (error) {
        yield put(getKeitatosStatFailure(error));
    }
}

  function* addKtSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postCheckerApi, data);
      yield put(addKeitatosSuccess(response));
      yield put(modalKeitatos(false))
    } catch (error) {
      yield put(addKeitatosFail(error));
    }
  }
  //
  function* updateKtSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updateCheckerApi, data, id);
      yield put(updKeitatosSuccess(response));
      yield put(modalKeitatos(false))
    }catch (error) {
      yield put(updKeitatosFail(error))
    }
  }
  //
  function* delKtSaga({id} : any) {
    try{
      yield call(delCheckerApi, id);
      yield put(delKeitatosSuccess(id));
    }catch (error) {
      yield put(delKeitatosFail(error))
    }
  }


function* checkerSaga() {
    yield takeEvery(KeitatosTypes.GET_KEITAROS, fetchKts)
    yield takeEvery(KeitatosTypes.GET_KEITAROS_ALL, fetchKtsAll)
    yield takeEvery(KeitatosTypes.GET_KEITARO, fetchKt)
    yield takeEvery(KeitatosTypes.GET_KEITAROS_STAT, fetchKtStat)
    yield takeEvery(KeitatosTypes.ADD_KEITAROS, addKtSaga)
    yield takeEvery(KeitatosTypes.UPD_KEITAROS, updateKtSaga)
    yield takeEvery(KeitatosTypes.DEL_KEITAROS, delKtSaga)

  }

  export default checkerSaga;