import {DomainchgState, DomainchgTypes} from "./actionTypes"

export const INIT_STATE: DomainchgState = {
  keitaros: [],
  campaigns: {
    bk_camps: [],
    wk_camps: [],
  },
  error: {},
  loadingKeitaros: false,
  loadedKeitaros: false,
  loadingCampaigns: false,
  loadedCampaigns: false,
  loadedChangeDomain: false,
  loadingChangeDomain: false
};

const Domainchg = (state = INIT_STATE, action :any) => {
  switch (action.type) {
    case DomainchgTypes.GET_KEITAROS:
      return {
        ...state,
        loadingKeitaros: true
      }

    case DomainchgTypes.GET_KEITAROS_SUCCESS:
      return {
        ...state,
        keitaros: action.payload,
        loadingKeitaros: false
      }

    case DomainchgTypes.CLEAR_KEITAROS:
        return {
          ...state,
          keitaros: [],
          loadingKeitaros: false,
          loadedKeitaros: false
        }

    case DomainchgTypes.SUBMIT_CHANGE_DOMAIN:
      return {
        ...state,
        loadingChangeDomain: true
      }

    case DomainchgTypes.SUBMIT_CHANGE_DOMAIN_SUCCESS:
      return {
        ...state,
        loadedChangeDomain: true,
        loadingChangeDomain: false
      }

    case DomainchgTypes.GET_KEITAROS_CAMPAINS:
      return {
        ...state,
        loadingCampaigns: true
      }

    case DomainchgTypes.GET_KEITAROS_CAMPAINS_SUCCESS:
      return {
        ...state,
        campaigns: {
          bk_camps: action.payload.bk_camps,
          wk_camps: action.payload.wk_camps
        },
        loadingCampaigns: false
      }

    case DomainchgTypes.CLEAR_KEITAROS_CAMPAINS:
      return {
        ...state,
        campaigns: {bk_camps: [], wk_camps: []},
        loadingCampaigns: false,
        loadedCampaigns: false
      }


    case DomainchgTypes.KEITAROS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Domainchg
