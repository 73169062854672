import React, {useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import {Link} from "react-router-dom";

import { modalLead } from "../../../store/leadDashboard/actions"
import usePermissions from "src/hooks/usePermissions";
import {useDispatch, useSelector} from "react-redux";
const TableLead = React.memo((props: any) =>  {
    const { data, pageSize, setRes} = props;
    const dispatch = useDispatch();


    const {hasPermission} = usePermissions();
    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();



    const handleLeadsClicks = () => {
        dispatch(modalLead(true))
    };



    const leads = useMemo(() =>
            data.map((item: any) => ({
                fcompany: item.fcompany || null,
                femail: item.femail  || null,
                fmb_id: item.fmb_id  || null,
                fname: item.fname  || null,
                foffer: item.foffer  || null,
                fphone: item.fphone  || null,
                keitaro_ip: item.keitaro_ip  || null,
                lname: item.lname  || null,
                offer_id: item.offer_id  || null,
                offer_url: item.offer_url  || null,
                time: item.time  || null,
                response: item.response  || null,
            })).filter((item: any) => item !== null),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                Header: "fcompany",
                accessor: "fcompany",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "femail",
                accessor: "femail",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "fmb_id",
                accessor: "fmb_id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "fname",
                accessor: "fname",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "foffer",
                accessor: "foffer",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "fphone",
                accessor: "fphone",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "keitaro_ip",
                accessor: "keitaro_ip",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "lname",
                accessor: "lname",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "offer_id",
                accessor: "offer_id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "offer_url",
                accessor: "offer_url",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "time",
                accessor: "time",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                            // hasPermission("delete-brokers") && 
                            <Link className="text-succes" to="#">
                                <i
                                    className="mdi mdi-cloud-print font-size-18"
                                    id="deletetooltip"
                                    onClick={() => {
                                        const brokers = cellProps.row.original;
                                        setRes(brokers)
                                        handleLeadsClicks()
                                        
                                    }}
                                ></i>
                            </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);


    return (
        <>
            <TableContainer
                columns={columns}
                data={leads}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

        </>
    )
})
TableLead.displayName = "TableLead";
export default React.memo(TableLead)