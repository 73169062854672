import React, { FC, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import NoSearchResult from 'src/components/Common/NoSearchResult';
import TableOrders from './components/TableOrders';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, modalOrders } from 'src/store/actions';
import ModalOrder from './components/Modal';
import usePermissions from "../../hooks/usePermissions";

let filter = {'per_page': 1000};

const Orders:FC = () => {
    document.title = "Order | Dashboard";
    const {data, loading} = useSelector((state: any) => state.orders)
    const isOpenModal = useSelector((state: any) => state.orders.isOpenModal);
    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();
    const navigate = useNavigate();

    const handleOrdersClicks = () => {
        dispatch(modalOrders(true))
    };

    useEffect(() => {
        dispatch(getOrders('', filter))
    } , [])

    useEffect(() => {
        const permission = hasPermission('view-orders');
        if (!permission) {
          navigate('/no-access');
        }
      }, [hasPermission, navigate]);

	return (
		<div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Orders" breadcrumbItem="Orders" />

                    <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {hasPermission("create-order") &&
                                     
                                        <Link to="#" className="btn btn-light" onClick={handleOrdersClicks}>
                                            <i className="bx bx-plus me-1"></i> Add New
                                        </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableOrders data={data} pageSize={20}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                {isOpenModal && <ModalOrder isOpen={isOpenModal} />}
            </div>
	)
}

export default Orders;