export enum TeamTypes {
    GET_TEAMS = "@@team/GET_TEAMS",
    GET_TEAMS_SUCCESS = "@@team/GET_TEAMS_SUCCESS",
    CLEAR_TEAMS = "@@team/CLEAR_TEAMS",
    GET_TEAMS_ALL = "@@team/GET_TEAMS_ALL",
    GET_TEAMS_ALL_SUCCESS = "@@team/GET_TEAMS_ALL_SUCCESS",
    CLEAR_TEAMS_ALL = "@@team/CLEAR_TEAMS_ALL",
    GET_TEAM = "@@team/GET_TEAM",
    GET_TEAM_SUCCESS = "@@team/GET_TEAM_SUCCESS",
    CLEAR_TEAM = "@@team/CLEAR_TEAM",
    ADD_TEAM = "@@team/ADD_TEAM",
    ADD_TEAM_SUCCESS = "@@team/ADD_TEAM_SUCCESS",
    UPD_TEAM = "@@team/UPD_TEAM",
    UPD_TEAM_SUCCESS = "@@team/UPD_TEAM_SUCCESS",
    DEL_TEAM = "@@team/DEL_TEAM",
    DEL_TEAM_SUCCESS = "@@team/DEL_TEAM_SUCCESS",
    GET_TEAM_MEMBERS = "@@team/GET_TEAM_MEMBERS",
    GET_TEAM_MEMBERS_SUCCESS = "@@team/GET_TEAM_MEMBERS_SUCCESS",

    TEAM_FAIL = "@@team/TEAM_FAIL",
    MODAL_TEAM = "@@team/MODAL_TEAM"
}

export interface TeamState {
    data  : Array<any>;
    item: Object
    members: Array<any>;
    pagination: Object
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}
