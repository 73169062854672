import {KeitarosTypes, KeitarosState} from "./actionsTypes";


export const INIT_STATE: KeitarosState = {
    data: [],
    error: {},
    loading: false,
    landsOffers: {},
    uni: {}
}

const keitarosReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case KeitarosTypes.GET_KEITAROS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case KeitarosTypes.GET_KEITAROS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case KeitarosTypes.FAIL_KEITAROS:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case KeitarosTypes.GET_LANDS_OFFERS_LIST:
            return {
                ...state,
                landsOffers: action.payload,
                loading: false
            }
        case KeitarosTypes.GET_LANDS_OFFERS_LIST_SUCCESS:
            return {
                ...state,
                landsOffers: action.payload,
                loading: false
            }
        case KeitarosTypes.FAIL_GET_LANDS_OFFERS_LIST:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case KeitarosTypes.POST_KEITAROS:

            return {
                ...state,
                uni: action.payload,
                loading: true
            }
            case KeitarosTypes.POST_KEITAROS_SUCCESS:
                return {
                    ...state,
                    uni: action.payload,
                    loading: false
                }

                case KeitarosTypes.FAIL_POST_KEITAROS:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false
                    }
        default:
            return state
    }
}

export default keitarosReducer;