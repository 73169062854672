import { DashboardTypes } from "./actionTypes";

export const getDashboard = () => ({
    type: DashboardTypes.GET_DASHBOARD,
  })
  
  export const getDashboardSuccess = (payload : any) => ({
    type: DashboardTypes.GET_DASHBOARD_SUCCESS,
    payload,
  })

export const getDashboardFailure = (payload : any) => ({
    type: DashboardTypes.GET_DASHBOARD_FAILURE,
    payload
})