import React, {useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch} from "react-redux";
import {delBroker, getBroker } from "../../../store/brokers/actions";
import usePermissions from "src/hooks/usePermissions";

const TableBroker = React.memo((props: any) =>  {
    const { data, pageSize, } = props

    const dispatch = useDispatch();

    const {hasPermission} = usePermissions();

    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getBroker(arg.id))
    };

    const brokers = useMemo(() =>
            data.map((item: any) => ({
                id: item.id.toString() || null,
                name: item.name  || null,
                ci: item.ci  || null,
                created_at: moment(item.created_at).format("YYYY-MM-DD")  || null,
            })).filter((item: any) => item !== null),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "ci",
                accessor: "ci",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                            hasPermission("update-brokers") && 
                                <Link className="text-success" to="#" onClick={() => {
                                    const brokerData = cellProps.row.original;
                                    onClickUpdate(brokerData)
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" ></i>
                                </Link>
                            }
                            {
                            hasPermission("delete-brokers") && 
                            <Link className="text-danger" to="#">
                                <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => {
                                        const brokers = cellProps.row.original;
                                        onClickDelete(brokers);
                                    }}
                                ></i>
                            </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteBroker = () => {
        dispatch(delBroker(contact.id));
        setDeleteModal(false);
    };

    return (
        <>
            <TableContainer
                columns={columns}
                data={brokers}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteBroker}
                onCloseClick={() => setDeleteModal(false)}
            />}
        </>
    )
})
TableBroker.displayName = "TableBroker";
export default React.memo(TableBroker)