import React, {useEffect} from "react";
import {  useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {clearBrokers, modalBroker, getBrokers } from "../../store/brokers/actions";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TableBroker from "./components/TableBroker";
import NoSearchResult from "../../components/Common/NoSearchResult";
import ModalBroker from "./components/ModalBroker"
import usePermissions from "src/hooks/usePermissions";

const Brokers = () => {
    document.title = "Broker List | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();
    const navigate = useNavigate();

    const data = useSelector((state: any) => state.Brokers.data || []);
    const loading = useSelector((state: any) => state.Brokers.loading || false);
    const isOpenModal = useSelector((state: any) => state.Brokers.isOpenModal || false);


    let filter = {'per_page': 1000};

    useEffect(() => {
        dispatch(getBrokers('', filter))

        return () => {
        dispatch(clearBrokers())
        };
    }, []);

    const handleBrokerClicks = () => {
        dispatch(modalBroker(true))
    };

    useEffect(() => {
        const permission = hasPermission('view-brokers');
        if (!permission) {
        navigate('/no-access');
        }
    }, [hasPermission, navigate]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Dashboard" breadcrumbItem="Brokers" />


                <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                      hasPermission("create-brokers") &&
                                    <Link to="#" className="btn btn-light" onClick={handleBrokerClicks}>
                                        <i className="bx bx-plus me-1"></i> Add New
                                    </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableBroker data={data} pageSize={10}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {isOpenModal && <ModalBroker isOpen={isOpenModal} />}
                </Container>
            </div>
        </React.Fragment>
);
};

export default Brokers;