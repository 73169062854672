import React, { FC, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Select from "../../components/Common/Select";
import { useDispatch, useSelector } from "react-redux";
import { getKeitatos, getLandsOffersList } from "../../store/uni/actions";
import TableChecker from "./components/TableChecker";

export const Uni: FC = () => {
    const [ktId, setKtId] = useState<number | null>(null);

    return (
        <div className="page-content">
            <StepOne setKtId={setKtId} />
            <StepTwo ktId={ktId} />
        </div>
    );
};

interface StepOneProps {
    setKtId: (id: number) => void;
}

const StepOne: FC<StepOneProps> = ({ setKtId }) => {
    const dispatch = useDispatch();
    const data = useSelector((state: any) => state.keitarosReducer.data);

    useEffect(() => {
        dispatch(getKeitatos());
    }, [dispatch]);

    const ktList = useMemo(() => {
        return data?.map((item: any) => ({
            value: item.id,
            label: item.ip,
        })) || [];
    }, [data]);

    const handleChange = (value: any) => {
        setKtId(value.value);
    };

    return (
        <Card style={{ width: "100%" }}>
            <CardHeader>Choose your keitaro</CardHeader>
            <CardBody>
                <Select
                    isSearchable
                    name="team_id"
                    label="Team"
                    placeholder="Keitaro"
                    options={ktList}
                    onChange={handleChange}
                />
            </CardBody>
        </Card>
    );
};

interface StepTwoProps {
    ktId: number | null;
}

const StepTwo: FC<StepTwoProps> = ({ ktId }) => {
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const landsOffers = useSelector((state: any) => state.keitarosReducer.landsOffers);

    useEffect(() => {
        if (ktId !== null) {
            dispatch(getLandsOffersList(ktId));
        }
    }, [dispatch, ktId]);

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => toggle("1")}
                        style={{ cursor: "pointer" }}
                    >
                        Offers
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => toggle("2")}
                        style={{ cursor: "pointer" }}
                    >
                        Lands
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            {ktId !== null && <TableChecker data={landsOffers?.offers || []} pageSize={10} ktId={ktId} />}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            {ktId !== null && <TableChecker data={landsOffers?.lands || []} pageSize={10} ktId={ktId} />}
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
};
