import { KeitarosTypes } from "./actionsTypes";

export const getKeitatos = () =>({
    type: KeitarosTypes.GET_KEITAROS,
})
export const getKeitatosSuccess = (data : any) =>({
    type: KeitarosTypes.GET_KEITAROS_SUCCESS,
    payload: data,
})

export const getKeitatosFail = (error : any) => ({
    type: KeitarosTypes.FAIL_KEITAROS,
    payload: error,
})

export const getLandsOffersList = (id: any) =>({
    type: KeitarosTypes.GET_LANDS_OFFERS_LIST,
    id
})
export const getLandsOffersListSuccess = (data : any) =>({
    type: KeitarosTypes.GET_LANDS_OFFERS_LIST_SUCCESS,
    payload: data,
})

export const getLandsOffersListFail = (error : any) => ({
    type: KeitarosTypes.FAIL_GET_LANDS_OFFERS_LIST,
    payload: error,
})


export const postKeitaros = (data: any) => ({
    type: KeitarosTypes.POST_KEITAROS,
    data
});

export const postKeitarosSuccess = (data: any) => ({
    type: KeitarosTypes.POST_KEITAROS_SUCCESS,
    payload: data
});

export const postKeitarosFail = (error: any) => ({
    type: KeitarosTypes.FAIL_POST_KEITAROS,
    payload: error
});