import React, {useEffect, useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch} from "react-redux";


import { delOrders, getOrder, getTeams, modalRoles } from "src/store/actions";
import usePermissions from "src/hooks/usePermissions";
import {OrderStatusEnum} from "../../../enums/OrderStatusEnum";

let filter = {'per_page': 5};

const TableOrders = React.memo((props: any) =>  {
    const { data, pageSize, } = props

    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();

    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getOrder(arg.id))
        dispatch(modalRoles(true))
    };

    const orderList = useMemo(() =>
            data.map((item: any) => ({
                id: item.id || null,
                name: item.name  || null,
                created_at: moment(item.created_at).format("YYYY-MM-DD")  || null,
                price: item.price || 0,
                from: item.from || null,
                to: item.to || null, 
                type: item.type || null,
                user: item?.user?.name || "--",
            })).filter((item: any) => item !== null),
        [data]
    );

    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteTeam = () => {
        dispatch(delOrders(contact.id));
        setDeleteModal(false);
    };

    useEffect(() => {
            dispatch(getTeams('', filter))
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Price",
                accessor: "price",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "From",
                accessor: "from",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "To",
                accessor: "to",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Type",
                accessor: "type",
                filterable: true,
                disableFilters: true,
                sortable: true,
                Cell: (cellProps: any) => {
                    let bg = "";
                    switch (cellProps.row.values.type) {
                        case 1 : bg = "success"; break;
                        case 2 : bg = "warning"; break;
                        case 3 : bg = "danger"; break;
                    }
                    return (
                        <span className={`badge bg-${bg}-subtle text-${bg}`}>{OrderStatusEnum[cellProps.row.values.type]}</span>
                    )
                }
            },
            {
                Header: "User closed",
                accessor: "user",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                               hasPermission("update-order") &&
                                <Link className="text-success" to="#" onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickUpdate(userData)
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" ></i>
                                </Link>
                            }
                            {
                            hasPermission("delete-order") &&
                                <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                            const team = cellProps.row.original;
                                            onClickDelete(team);
                                        }}
                                    ></i>
                                </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={orderList}
                isGlobalFilter={true}
                customSort={[{id: 'id', desc: true}]}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTeam}
                onCloseClick={() => setDeleteModal(false)}
            />}
        </React.Fragment>
    )
})
TableOrders.displayName = "TableOrders";
export default React.memo(TableOrders)