import React, { useMemo } from "react";
import {Form, ModalBody, ModalHeader, Modal, Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import { modalLead } from "../../../store/leadDashboard/actions"
import ReactJson from "react-json-view";



interface Props {
  isOpen: boolean;
  islead: any

}
interface IFormValues {
  name: string;
  ci: string;
}
const ModalLead = ({isOpen, islead} : Props) => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalLead(false))
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
          Response
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
            {/*<pre>{JSON.stringify(islead, null, 2)}</pre>*/}
                <ReactJson src={islead} name={false}/>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalLead;