import { call, put, takeEvery } from "redux-saga/effects"
import { DomainchgTypes } from "./actionTypes"

import {
  domainchgFail,
  getKeitarosCampaignsSuccess,
  getKeitarosSuccess, submitChangeDomainSuccess,
} from "./actions"

import {
  getDomainchgKeitaros as getDomainchgKeitarosApi,
    getDomainchgCampaigns as getDomainchgCampaignApi,
  setDomainChange as setDomainChangeApi
} from "../../helpers/backend_helper"

function* fetchKeitaros() {
  try {
    const response : Promise<any> = yield call(getDomainchgKeitarosApi)
    yield put(getKeitarosSuccess(response))
  } catch (error) {
    yield put(domainchgFail(error))
  }
}

function* fetchChangeDomain({data}: any) {
  try {
    yield call(setDomainChangeApi, data)
    yield put(submitChangeDomainSuccess())
  } catch (error) {
    yield put(domainchgFail(error))
  }
}

function* fetchKeitarosCampaigns({id}: any) {
  try {
    const response : Promise<any> = yield call(getDomainchgCampaignApi, id)
    yield put(getKeitarosCampaignsSuccess(response))
  } catch (error) {
    yield put(domainchgFail(error))
  }
}



function* jiraSaga() {
  yield takeEvery(DomainchgTypes.GET_KEITAROS, fetchKeitaros)
  yield takeEvery(DomainchgTypes.SUBMIT_CHANGE_DOMAIN, fetchChangeDomain)
  yield takeEvery(DomainchgTypes.GET_KEITAROS_CAMPAINS, fetchKeitarosCampaigns)
}

export default jiraSaga;