export enum KeitarosTypes {
    GET_KEITAROS = "@@keitaros/GET_KEITAROS",
    GET_KEITAROS_SUCCESS = "@@keitaros/GET_KEITAROS_SUCCESS",
    FAIL_KEITAROS = "@@keitaros/FAIL_KEITAROS",

    GET_LANDS_OFFERS_LIST = "@@keitaros/GET_LANDS_OFFERS_LIST",
    GET_LANDS_OFFERS_LIST_SUCCESS = "@@keitaros/GET_LANDS_OFFERS_LIST_SUCCESS",
    FAIL_GET_LANDS_OFFERS_LIST = "@@keitaros/FAIL_GET_LANDS_OFFERS_LIST",


    POST_KEITAROS = "@@keitaros/POST_KEITAROS",
    POST_KEITAROS_SUCCESS = "@@keitaros/POST_KEITAROS_SUCCESS",
    FAIL_POST_KEITAROS = "@@keitaros/FAIL_POST_KEITAROS",
}
export interface KeitarosState {
    data  : Array<any>;
    error : Object;
    loading: boolean;
    landsOffers: Object;
    uni: Object;
}

