import React, { useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import usePermissions from "src/hooks/usePermissions";
import { postKeitaros} from "../../../store/uni/actions";

const TableChecker = React.memo((props: any) => {
    const { data, pageSize, ktId } = props;
    const dispatch = useDispatch();
    const { hasPermission } = usePermissions();

    const onClickUpdate = (arg: any) => {
        dispatch(postKeitaros({ o_id: arg?.id, path: arg?.path, k_id: ktId, type: arg.type }));
    };

    const dataList = useMemo(() => {
        return data?.map((item: any) => ({
            name: item.name,
            id: item.id,
            path: item.path,
            type: item.type,
        })) || [];
    }, [data]);

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id",
            filterable: true,
            disableFilters: true,
            sortable: true,
        },
        {
            Header: "Name",
            accessor: "name",
            filterable: true,
            disableFilters: true,
            sortable: true,
        },
        {
            Header: "Path",
            accessor: "path",
            filterable: true,
            disableFilters: true,
            sortable: true,
        },
        {
            Header: "Type",
            accessor: "type",
            filterable: true,
            disableFilters: true,
            sortable: true,
        },
        {
            Header: "Action",
            Cell: (cellProps: any) => (
                <div className="d-flex gap-3">
                    {hasPermission("update-role") && (
                        <Link className="text-success" to="#" onClick={() => onClickUpdate(cellProps.row.original)}>
                            <i className="mdi mdi-pencil font-size-18"></i>
                        </Link>
                    )}
                </div>
            ),
        },
    ], [hasPermission]);

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={dataList}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />
        </React.Fragment>
    );
});

TableChecker.displayName = "TableChecker";
export default TableChecker;
