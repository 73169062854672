import React, {useEffect, useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import usePermissions from "src/hooks/usePermissions";
import {delKeitatos, getKeitato, getKeitatosStat, modalKeitatos} from "../../../store/checker/actions";
import FeatherIcon from "feather-icons-react";

let filter = {'per_page': 5};

const TableChecker = React.memo((props: any) =>  {

    const { data, pageSize, } = props
    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();

    const isOpenModal = useSelector((state: any) => state.permissions.isOpenModal || false);


    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getKeitato(arg.id))
        dispatch(getKeitatosStat(arg.id));
        dispatch(modalKeitatos(true))
    };



    const list = useMemo(() =>
            data.map((item: any) => ({
                id: item.id || null,
                ip: item.ip  || null,
                key:  item.key || null,
                created_at: moment(item.created_at).format("YYYY-MM-DD")  || null,
            })).filter((item: any) => item !== null),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "IP",
                accessor: "ip",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Key",
                accessor: "key",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                               hasPermission("update-role") &&  
                                <Link className="text-success" to="#" onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickUpdate(userData)
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" ></i>
                                </Link>
                            }
                            {
                            hasPermission("delete-role") &&    
                                <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                            const team = cellProps.row.original;
                                            onClickDelete(team);
                                        }}
                                    ></i>
                                </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteTeam = () => {
        // console.log(contact.id)
        dispatch(delKeitatos(contact.id));
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={list}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTeam}
                onCloseClick={() => setDeleteModal(false)}
            />}
        </React.Fragment>
    )
})
TableChecker.displayName = "TableTeam";
export default TableChecker