import React, { useMemo, useEffect } from "react";
import { Form, ModalBody, ModalHeader, Modal, Row, Col, FormGroup, Label, InputGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";

import { clearPriceDay, modalPriceDay, getPriceDay, addPriceDay, updPriceDay } from "../../../store/price_day/actions";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Select from "../../../components/Common/Select";
import usePermissions from "src/hooks/usePermissions";


interface Props {
  isOpen: boolean;
  //toggle: () => void;
}
interface IFormValues {
  price: string;
  date?: string | null;
  broker_id?: string | null;
}
const ModalPrice = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const {hasPermission} = usePermissions();

  const item = useSelector((state: any) => state.priceDay.item || {});

  const dataBrokerId = useSelector((state: any) => state.Brokers.dataBrokerId || []);


  const validationSchema: yup.Schema<IFormValues> = yup.object().shape({
    price: yup.string()
      .min(1, "min 1 character")
      .max(75, "max 75 characters")
      .matches(/^[0-9]+$/, "Only numbers")
      .required("required"),
    date: yup.string().nullable(),
    broker_id: yup.string().nullable(),

  });

  const initialValues = useMemo((): IFormValues => {
    return {
      price: item?.price || "",
      date: item?.date || null,
      broker_id: item?.broker_id || null,
    }
  }, [item]);

  const handleSubmitForm = () => {

    let brokeriD = values.broker_id?.toString();
    let date = values.date?.split(' ')[0];

    let newPriceday = {
      "broker_id": brokeriD,
      "price": values.price,
      "date": values.date,
    }

    if (item.id) {
      dispatch(updPriceDay(newPriceday, item.id));
    } else {
      dispatch(addPriceDay(newPriceday));
    }
  };


  const {
    values,
    validateForm,
    handleChange,
    submitForm,
    handleBlur,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true
  });


  const findBrokerById = (id: any): void => {
        const res = dataBrokerId.find((broker: any) => broker.value === id);
        return res
  };




  const formatRangeDate = (dateRange: [Date | null, Date | null]): any => {

    let stringDate = dateRange
      .map(date => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      })
      .join(", ");
    setFieldValue("date", stringDate);
  };



  const handlerClickSubmit = async () => {
    const curErrors = await validateForm();
    const curErrorsKeys = Object.keys(curErrors);
    if (curErrorsKeys.length) {
      const el = document.getElementById(curErrorsKeys[0]);
      if (el) el.focus();
    }
    submitForm();
  };

  const close = () => {
    dispatch(modalPriceDay(false));
    dispatch(clearPriceDay());
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        {item?.id ? "Edit" : "Add"} Price day
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <LabelInput
                  label="Price*"
                  placeholder="Price*"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price || ""}
                  hasError={!!(errors.price && touched.price)}
                  errorText={errors.price}
                />
              </div>
              <Row>
                <FormGroup className="mb-4">
                  <Label>Date Range</Label>
                  <InputGroup>
                    {item?.id ?
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        value={values.date || ""}
                        options={{
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(date: any) => {
                          formatRangeDate(date)
                        }}
                      /> :
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(date: any) => {
                          formatRangeDate(date)
                        }}
                      />
                    }

                  </InputGroup>
                </FormGroup>
              </Row>
              <div className='mb-3'>
                <Label>Broekr ID: </Label>
                <Select
                  isSearchable
                  name="broker_id"
                  label="Broekr ID"
                  placeholder="Broker ID"
                  options={dataBrokerId}
                  defaultValue={findBrokerById(item.broker_id)}
                  onChange={(value: any) => setFieldValue("broker_id", value.value)}

                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={handlerClickSubmit}
                  disabled={!isValid}
                > Save </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalPrice;