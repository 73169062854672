import React, {useEffect} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import {clearPricesDay, modalPriceDay, getPricesDay} from "../../store/price_day/actions";
import {getBrokersId} from "../../store/brokers/actions";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TablePrice from "./components/TablePrice";
import NoSearchResult from "../../components/Common/NoSearchResult";
import ModalPrice from "./components/ModalPrice";
import usePermissions from "src/hooks/usePermissions";


const PriceDay = () => {
    document.title = "Price day | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();

    const data = useSelector((state: any) => state.priceDay.data || []);
    const loading = useSelector((state: any) => state.priceDay.loading || false);
    const isOpenModal = useSelector((state: any) => state.priceDay.isOpenModal || false);

    let filter = {'per_page': 1000};

    useEffect(() => {
        dispatch(getPricesDay('', filter))

        return () => {
            dispatch(clearPricesDay())
        };
    }, []);

    const handlePriceDayClicks = () => {
        dispatch(modalPriceDay(true));
        dispatch(getBrokersId());
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Dashboard" breadcrumbItem="Price day" />

                <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                    hasPermission("create-price-day") &&
                                        <Link to="#" className="btn btn-light" onClick={handlePriceDayClicks}>
                                            <i className="bx bx-plus me-1"></i> Add New
                                        </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TablePrice data={data} pageSize={10}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {isOpenModal && <ModalPrice isOpen={isOpenModal} />}
                </Container>
            </div>
        </React.Fragment>
);
};

export default PriceDay;