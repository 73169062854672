export enum KeitatosTypes{
    GET_KEITAROS = "@@kt/GET_KEITAROS",
    GET_KEITAROS_SUCCESS = "@@kt/GET_KEITAROS_SUCCESS",
    GET_KEITAROS_FAILURE = "@@kt/GET_KEITAROS_FAILURE",

    GET_KEITAROS_ALL = "@@kt/GET_KEITAROS_ALL",
    GET_KEITAROS_ALL_SUCCESS = "@@kt/GET_KEITAROS_ALL_SUCCESS",
    CLEAR_KEITAROS_ALL = "@@kt/CLEAR_KEITAROS_ALL",

    GET_KEITARO = "@@kt/GET_KEITARO",
    GET_KEITARO_SUCCESS = "@@kt/GET_KEITARO_SUCCESS",
    GET_KEITARO_FAILURE = "@@kt/GET_KEITARO_FAILURE",


    ADD_KEITAROS = "@@roles/ADD__KEITAROS",
    ADD_KEITAROS_SUCCESS = "@@roles/ADD_KEITAROS_SUCCESS",

    UPD_KEITAROS = "@@kt/UPD_KEITAROS",
    UPD_KEITAROS_SUCCESS = "@@kt/UPD_KEITAROS_SUCCESS",
    
    DEL_KEITAROS = "@@kt/DEL_KEITAROS",
    DEL_KEITAROS_SUCCESS = "@@kt/DEL_KEITAROS_SUCCESS",

    KEITAROS_FAILURE = "@@kt/KEITAROS_FAIL",
    KEITAROS_MODAL = "@@kt/KEITAROS_MODAL",
    CLEAR_KEITAROS = "@@kt/CLEAR_KEITAROS",


    GET_KEITAROS_STAT = "@@kt/GET_KEITAROS_STAT",
    GET_KEITAROS_STAT_SUCCESS = "@@kt/GET_KEITAROS_STAT_SUCCESS",
    GET_KEITAROS_STAT_FAILURE = "@@kt/GET_KEITAROS_STAT_FAILURE",

}


export interface CheckerState {
    data  : Array<any>;
    kio_list  : Array<any>;
    loading : boolean;
    error : any;
    isOpenModal: boolean,
    loaded: boolean,
    item: Object
    stat: Object
}


