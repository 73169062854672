import {UserTypes,UserState} from "./actionTypes"

export const INIT_STATE : UserState = {
  data: [],
  item: {},
  pagination: {},
  members: [],
  error: {},
  loading: false,
  loaded: false,
  isOpenModal: false,
}

const teams = (state = INIT_STATE, action :any) => {
  switch (action.type) {
    case UserTypes.GET_USERS:
      return {
        ...state,
        loading: true
      }

    case UserTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loaded: true,
        loading: false
      }

    case UserTypes.CLEAR_USERS:
        return INIT_STATE

    case UserTypes.GET_USERS_ALL:
      return {
        ...state
      }

    case UserTypes.GET_USERS_ALL_SUCCESS:
      return {
        ...state,
        members: action.payload,
      }

    case UserTypes.CLEAR_USERS_ALL:
      return {
        ...state,
        members: [],
        error: {},
      }


    case UserTypes.GET_USER_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }

    case UserTypes.CLEAR_USER:
      return {
        ...state,
        item: {},
        error: {},
      }

    case UserTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      }

    case UserTypes.UPD_USER_SUCCESS:
      const items = state.data.map((item : any) => {
        return (item.id === action.payload.id) ? action.payload : item
      })
      return {
        ...state,
        data: items,
        error: {},
        loading: false,
        loaded: true
      }

    case UserTypes.DEL_USER_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item : any) => item.id.toString() !== action.payload.toString() ),
        error: {},
        loading: false,
        loaded: true
      }

    case UserTypes.USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loading: false
      }

    case UserTypes.MODAL_USER:
      return {
        ...state,
        isOpenModal: action.status
      }

    default:
      return state
  }
}

export default teams
