import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody, Input
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TableTracker from "./components/TableTracker"
import ModalTracker from "./components/ModalTracker";
import { getTraker, modalTraker, getUpdateBrokerName } from "src/store/tracker/actions"
import { Link } from "react-router-dom";


import NoSearchResult from "../../components/Common/NoSearchResult";
import usePermissions from "src/hooks/usePermissions";
import CardCap from "./components/CardCap";

interface Offer {
    [key: string]: number;
}

interface DataEntry {
    name: string;
    boxes: number[];
    offers: Offer;
    capa: number;
    capaleft: number;
    capatoday: number;
    capastatus: number;
}

const Tracker = () => {
    document.title = "Tracker | Capa";
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [disabletLink, setDisabledLink] = useState<boolean>();
    const [disabletLinkUpdate, setDisabledLinkUpdate] = useState<boolean>();
    const [searchTerm, setSearchTerm] = useState("");

    const data = useSelector((state: any) => state.traker.data || {});
    const isOpenModal = useSelector((state: any) => state.traker.isOpenModal || false);
    const { hasPermission } = usePermissions();
    const navigate = useNavigate();

    const transformedData = useMemo(() => {
        setDisabledLink(false);
        return Object.entries(data).map(([key, value]): any => {
            const typedValue = value as DataEntry;
            return {
                brokerName: typedValue.name,
                boxes: Array.isArray(typedValue.boxes) ? typedValue.boxes.join(", ") : typedValue.boxes,
                Offers: Object.entries(typedValue.offers).map(([offerName, offerValue]) => `${offerName}: ${offerValue}`).join(", "),
                capa: typedValue.capa,
                capaleft: typedValue.capaleft,
                capatoday: typedValue.capatoday,
                flag: typedValue.capastatus,
            };
        });
    }, [data]);

    const filteredData = useMemo(() => {
        return transformedData.filter((entry) => {
            return entry.brokerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                entry.Offers.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [searchTerm, transformedData]);

    const refresh = () => {
        dispatch(getTraker());
        setDisabledLink(true)
    }

    const handlerUpdateBrokName = () => {
        dispatch(getUpdateBrokerName());
        setDisabledLinkUpdate(true);
        setTimeout(() => {
            setDisabledLinkUpdate(false);
        }, 5000);
    };

    const handleTrackersClicks = () => {
        dispatch(modalTraker(true))
    };

    useEffect(() => {
        const permission = hasPermission('view-tracker');
        if (!permission) {
            navigate('/no-access');
        }
    }, [hasPermission, navigate]);

    useEffect(() => {
        dispatch(getTraker());
        const intervalId = setInterval(() => dispatch(getTraker()), 60000);
        return () => clearInterval(intervalId);
    }, [dispatch]);




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Tracker" />
                    <>
                        <Row className=" align-items-center">
                            <Col md={12} className="d-flex justify-content-between mb-3">
                                <div className="d-flex align-items-center  gap-2 ">
                                    <label className="justify-content-end fw-normal">
                                        <Input
                                            placeholder="Global search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <div className="d-flex align-items-center  gap-2">
                                    {

                                        hasPermission('user-view-tracker') &&
                                        <>
                                            <div>
                                            <Link to="#" className="btn btn-light" onClick={handleTrackersClicks}>
                                                <i className="bx bx-plus me-1"></i> Add New
                                            </Link>
                                        </div>
                                            <div>
                                                <Link to="#" className={disabletLink ? `btn btn-light disabled` : `btn btn-light`} onClick={refresh}>
                                                    Refresh
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="#" className={disabletLinkUpdate ? `btn btn-light disabled` : `btn btn-light`} onClick={handlerUpdateBrokName}>
                                                    Refresh Broker
                                                </Link>
                                            </div>
                                        </>
                                    }

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        {(Object.keys(data).length > 0 && !disabletLinkUpdate && !disabletLink) ?
                                            <Row>
                                                {filteredData.map((val, idx) => (
                                                    <CardCap data={val} id={"capa_" + idx} key={"capa_" + idx} />
                                                ))}
                                                {!transformedData.length &&
                                                    <NoSearchResult text={"Loading ..."} />
                                                }
                                            </Row>
                                            : <NoSearchResult text={"Loading ..."} />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    {isOpenModal && <ModalTracker isOpen={true} />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Tracker;