import { call, put, takeEvery } from "redux-saga/effects";
import {
    getKeitaro as getKeitatosApi,
    getLandsoffers as getLandsoffersApi,
    postKeitaros as postKeitarosApi
} from "../../helpers/backend_helper";
import { KeitarosTypes } from "./actionsTypes";
import {
    getKeitatosFail,
    getKeitatosSuccess,
    getLandsOffersListFail,
    getLandsOffersListSuccess,
    postKeitarosFail,
    postKeitarosSuccess
} from "./actions";

function* getKeitatosSaga() {
    try {
        const response: Promise<any> = yield call(getKeitatosApi);
        yield put(getKeitatosSuccess(response));
    } catch (e) {
        yield put(getKeitatosFail(e));
    }
}

function* getLandsOffers({ id }: any) {
    try {
        const response: Promise<any> = yield call(getLandsoffersApi, id);
        yield put(getLandsOffersListSuccess(response));
    } catch (e) {
        yield put(getLandsOffersListFail(e));
    }
}

function* postKeitaros({ data }: any) {
    try {
        const response: Promise<any> = yield call(postKeitarosApi, data);
        yield put(postKeitarosSuccess(response));
    } catch (e) {
        yield put(postKeitarosFail(e));
    }
}

function* watchGetKeitatos() {
    yield takeEvery(KeitarosTypes.GET_KEITAROS, getKeitatosSaga);
    yield takeEvery(KeitarosTypes.GET_LANDS_OFFERS_LIST, getLandsOffers);
    yield takeEvery(KeitarosTypes.POST_KEITAROS, postKeitaros);
}

export default watchGetKeitatos;
