import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {
  GET_JIRA_PROJECTS,
  GET_JIRA_STATISTICS,
  GET_KEITAROS,
  POST_UNI_KEITAROS
} from "./url_helper";

const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Edit profile
const postJwtProfile = (data: any) => post(url.POST_EDIT_JWT_PROFILE, data)

// Register Method
const postJwtRegister = (url: string, data: any) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = (data: any) => post(url.POST_JWT_LOGIN, data);

//Domainchg
export const getDomainchgKeitaros = () => get(`${url.GET_DOMAINCHG}/keitaros`);
export const getDomainchgCampaigns = (id:number) => get(`${url.GET_DOMAINCHG}/${id}/campaigns`);
export const setDomainChange = (data: any) => post(`${url.GET_DOMAINCHG}/chgdomain`, data);

//JIRA
export const getProjects = () => post(url.GET_JIRA_PROJECTS, {});
export const getJiraUsers = () => post(url.GET_JIRA_USERS, {});
export const getJiraStatistics = (data: any) => post(url.GET_JIRA_STATISTICS, data);

// Team Method
export const getTeam = (id: number) => get(`${url.GET_TEAMS}/${id}`);
export const getTeams = (next_page: string, filter: object) => get(next_page || url.GET_TEAMS, { params: filter });
export const postTeam = (data: any) => post(url.GET_TEAMS, data);
export const updateTeam = (data: any, id: number) => put(`${url.GET_TEAMS}/${id}`, data);
export const delTeam = (id: number) => del(`${url.GET_TEAMS}/${id}`);
// User Method
export const getUser = (id: number) => get(`${url.GET_USERS}/${id}`);
export const getUsers = (next_page: string, filter: object) => get(next_page || url.GET_USERS, { params: filter });
export const postUser = (data: any) => post(url.GET_USERS, data);
export const updateUser = (data: any, id: number) => put(`${url.GET_USERS}/${id}`, data);
export const delUser = (id: number) => del(`${url.GET_USERS}/${id}`);
// Broker Method
export const getBroker = (id: number) => get(`${url.GET_BROKERS}/${id}`);
export const getBrokers = (next_page: string, filter: object) => get(next_page || url.GET_BROKERS, { params: filter});
export const postBroker = (data: any) => post(url.GET_BROKERS, data);
export const updateBroker = (data: any, id: number) => put(`${url.GET_BROKERS}/${id}`, data);
export const delBroker = (id: number) => del(`${url.GET_BROKERS}/${id}`);
export const getBrokersId =() => get(`${url.GET_BROKERS}/all`);

// Spends Method
export const getSpend = (id: number) => get(`${url.GET_SPENDS}/${id}`);
export const getSpends = (next_page: string, filter: object) => get(next_page || url.GET_SPENDS, { params: filter });
export const postSpend = (data: any) => post(url.GET_SPENDS, data);
export const updateSpend = (data: any, id: number) => put(`${url.GET_SPENDS}/${id}`, data);
export const delSpend = (id: number) => del(`${url.GET_SPENDS}/${id}`);

// PriceDay Method
export const getPriceDay =(id: number) => get(`${url.GET_PRICE_DAY}/${id}`);
export const getPricesDay =(next_page: string, filter: object) => get(next_page || url.GET_PRICE_DAY, {params: filter});
export const postPriceDay = (data: any) => post(url.GET_PRICE_DAY, data);
export const updatePriceDay = (data: any, id: number) => put(`${url.GET_PRICE_DAY}/${id}`, data);
export const delPriceDay = (id: number) => del(`${url.GET_PRICE_DAY}/${id}`);

// chekin page
export const postCheckinUser = (data: any) => post(url.POST_ADD_USER_CHEKIN, data);
export const getCheckin = (filter: any) => get(`${url.GET_CHECKIN}`, { params: filter });
export const getPermissions = () => get(url.GET_PERMISSIONS)
export const getPermission = (id: number) => get(`${url.GET_PERMISSIONS}/${id}`)
export const postPermission = (data: any) => post(url.GET_PERMISSIONS, data)
export const updatePermission = (data: any, id: number) => put(`${url.GET_PERMISSIONS}/${id}`, data)
export const delPermission = (id: number) => del(`${url.GET_PERMISSIONS}/${id}`)

export const getRoles = () => get(url.GET_ROLES)
export const getRole = (id: number) => get(`${url.GET_ROLES}/${id}`)
export const postRole = (data: any) => post(url.GET_ROLES, data)
export const updateRole = (data: any, id: number) => put(`${url.GET_ROLES}/${id}`, data)
export const delRole = (id: number) => del(`${url.GET_ROLES}/${id}`)


// dashboard
export const getStatistic = (filter: any) => get(`${url.GET_STATISTIC}`, {params: filter})
export const getTeamsAll = () => get(url.GET_TEAMS_ALL)
export const getUsersAll = () => get(url.GET_USERS_ALL)

// orders
export const getOrders = (next_page: string, filter: object) => get(next_page || url.GET_ORDERS, { params: filter });
export const getOrder = (id: number) => get(`${url.GET_ORDERS}/${id}`)
export const getOrdersByTeam = (id: number) => get(`${url.GET_ORDERS}/team/${id}`)
export const postOrders = (data: any) => post(url.GET_ORDERS, data)
export const updateOrders = (data: any, id: number) => put(`${url.GET_ORDERS}/${id}`, data)
export const delOrders = (id: number) => del(`${url.GET_ORDERS}/${id}`)
// members
export const getTeamMembers = (id: number) => get(`${url.GET_TEAM_MEMBERS}${id}/users`)

// Tracker 
export const getTrackerData = () => get(`${url.GET_TRACKER}`)
export const postCapa = (data: any) => post(url.POST_CAPA, data)
export const getUpdateBrokerName = () => get(`${url.GET_UPDATE_BROKER_NAME}`)

export const getCheckers = () => get(url.GET_CHECKER)
export const getCheckersAll = () => get(url.GET_CHECKER_ALL)
export const getChecker = (id: number) => get(`${url.GET_CHECKER}/${id}`)


export const getCheckerStat = (id: number) => get(`${url.GET_CHECKER}/stat/${id}`)
export const postChecker = (data: any) => post(url.GET_CHECKER, data)
export const updateChecker = (data: any, id: number) => put(`${url.GET_CHECKER}/${id}`, data)

export const delChecker = (id: number) => del(`${url.GET_CHECKER}/${id}`)

export const getKeitaro = () => get(`${url.GET_KEITAROS}`)
export const getLandsoffers = (id: number) => get(`uni/${id}/getlandsofferslist`)
export const postKeitaros = (data: any) => post(url.POST_UNI_KEITAROS, data)

// leads Component
export const  getKeitaroDate =(data: any) => get(`${url.GET_KEITARO_DATA}?start_date=${data.from}&end_date=${data.to}`)
export const  postLeads =(data: any) => post(url.POST_OFFER_LEADS, data)


export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  postJwtProfile,
}