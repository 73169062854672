import { call, put, takeEvery } from "redux-saga/effects"
import { TeamTypes } from "./actionTypes"

import {
  getTeamsSuccess,
  getTeamsFail,
  getTeamSuccess,
  getTeamFail,
  modalTeam,
  addTeamSuccess,
  addTeamFail,
  updTeamFail,
  updTeamSuccess,
  delTeamSuccess, delTeamFail, getTeamMembersSuccess, getTeamsAllSuccess,
} from "./actions"

import {
  getTeams as getTeamsApi,
  getTeamsAll as getTeamsAllApi,
  getTeam as getTeamApi,
  postTeam as postTeamApi,
  updateTeam as updateTeamApi,
  delTeam as delTeamApi,
  getTeamMembers
} from "../../helpers/backend_helper"

function* fetchTeams({next_page, filter}: any) {
  try {
    const response : Promise<any> = yield call(getTeamsApi, next_page, filter)
    yield put(getTeamsSuccess(response))
  } catch (error) {
    yield put(getTeamsFail(error))
  }
}

function* fetchTeamsAll() {
  try {
    const response : Promise<any> = yield call(getTeamsAllApi)
    yield put(getTeamsAllSuccess(response))
  } catch (error) {
    yield put(getTeamsFail(error))
  }
}

function* fetchTeam({id}: any ) {
  try {
    const response: Promise<any> = yield call(getTeamApi, id);
    yield put(getTeamSuccess(response));
    yield put(modalTeam(true))
  } catch (error) {
    yield put(getTeamFail(error));
  }
}

function* fetchTeamMembers({id}: any ) {
  try {
    const response: Promise<any> = yield call(getTeamMembers, id);
    yield put(getTeamMembersSuccess(response));
  } catch (error) {
    yield put(getTeamFail(error));
  }
}

function* addTeamSaga({data}: any) {
  try {
    const response: Promise<any> = yield call(postTeamApi, data);
    yield put(addTeamSuccess(response));
    yield put(modalTeam(false))
  } catch (error) {
    yield put(addTeamFail(error));
  }
}

function* updateTeamSaga({data, id} : any) {
  try{
    const response : Promise<any> = yield call(updateTeamApi, data, id);
    yield put(updTeamSuccess(response));
    yield put(modalTeam(false))
  }catch (error) {
    yield put(updTeamFail(error))
  }
}

function* delTeamSaga({id} : any) {
  try{
    yield call(delTeamApi, id);
    yield put(delTeamSuccess(id));
  }catch (error) {
    yield put(delTeamFail(error))
  }
}


function* teamsSaga() {
  yield takeEvery(TeamTypes.GET_TEAMS, fetchTeams)
  yield takeEvery(TeamTypes.GET_TEAMS_ALL, fetchTeamsAll)
  yield takeEvery(TeamTypes.GET_TEAM, fetchTeam)
  yield takeEvery(TeamTypes.ADD_TEAM, addTeamSaga)
  yield takeEvery(TeamTypes.UPD_TEAM, updateTeamSaga)
  yield takeEvery(TeamTypes.DEL_TEAM, delTeamSaga)
  yield takeEvery(TeamTypes.GET_TEAM_MEMBERS, fetchTeamMembers)
}

export default teamsSaga;