 import React, {useEffect} from 'react'
 import {Card, CardBody, Col, Container, Row} from "reactstrap";
 import Breadcrumbs from "../../components/Common/Breadcrumb";
 import {Link} from "react-router-dom";
 import TableUser from "../Roles/components/TableTeam";
 import NoSearchResult from "../../components/Common/NoSearchResult";
 import ModalRoles from "./components/Modal";
 import {useDispatch, useSelector} from "react-redux";
 import {getKeitatos, modalKeitatos} from "../../store/checker/actions";
 import TableChecker from "./components/TableChecker";

 const Checker = () => {
  const {data, loading, isOpenModal} = useSelector((state: any) => state.checker);
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch(getKeitatos());
  }, []);


  const handleClicks = () => {
   dispatch(modalKeitatos(true))
  };

  return (
      <div className="page-content">
       <Container fluid>
        <Breadcrumbs title="Dashboard" breadcrumbItem="DNS Checker" />

        <Row className="align-items-center">
         <Col md={12}>
          <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
           <div>
            {
                // hasPermission("create-role") &&
                <Link to="#" className="btn btn-light" onClick={handleClicks}>
                 <i className="bx bx-plus me-1"></i> Add New
                </Link>
            }
           </div>
          </div>
         </Col>
        </Row>

        <Row>
         <Col lg="12">
          <Card>
           <CardBody className="border-bottom">
            { data.length > 0 && <TableChecker data={data} pageSize={20}/> }
            { loading ? ( <NoSearchResult /> ) : null }
           </CardBody>
          </Card>
         </Col>
        </Row>

       </Container>
       {isOpenModal && <ModalRoles isOpen={isOpenModal} />}
      </div>
  )
 }

 export default Checker;