import {DomainchgTypes} from "./actionTypes"

export const getKeitaros = () => ({
  type: DomainchgTypes.GET_KEITAROS
})

export const getKeitarosSuccess = (data : any) => {
  return {
    type: DomainchgTypes.GET_KEITAROS_SUCCESS,
    payload: data,
  }
}

export const clearKeitaros = () => ({
    type: DomainchgTypes.CLEAR_KEITAROS,
})

export const submitChangeDomain = (data:any) => ({
  type: DomainchgTypes.SUBMIT_CHANGE_DOMAIN,
  data
})
export const submitChangeDomainSuccess = () => ({
  type: DomainchgTypes.SUBMIT_CHANGE_DOMAIN_SUCCESS
})

export const getKeitarosCampaigns = (id: any) => ({
  type: DomainchgTypes.GET_KEITAROS_CAMPAINS,
  id
})

export const getKeitarosCampaignsSuccess = (data : any) => {
  return {
    type: DomainchgTypes.GET_KEITAROS_CAMPAINS_SUCCESS,
    payload: data,
  }
}

export const clearKeitarosCampaigns = () => ({
  type: DomainchgTypes.CLEAR_KEITAROS_CAMPAINS,
})



export const domainchgFail = (error : any) => ({
  type: DomainchgTypes.KEITAROS_FAIL,
  payload: error,
})