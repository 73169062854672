import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getStatistic } from "src/store/statistic/actions";
import { getDashboard } from "src/store/actions";
import Select from "../../components/Common/Select";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { formatDate } from "src/helpers/formatDate";
import NoSearchResult from "src/components/Common/NoSearchResult";
import usePermissions from "src/hooks/usePermissions";


const Dashboard = () => {
  document.title = "Chart | Dashboard";
  const [cplSeries, setCplSeries] = useState<number[]>([]);
  const [cplLabels, setCplLabels] = useState<string[]>([]);

  const [cpaSeries, setCpaSeries] = useState<number[]>([]);
  const [cpaLabels, setCpaLabels] = useState<string[]>([]);

  const [fromDateStr, setFromDateStr] = useState<string>();
  const [toDateStr, setToDateStr] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<any>(null);


  const { data, loaded } = useSelector((state: any) => state.statistic);
  const teamsAll = useSelector((state: any) => state.dashboard || {data: []});
  const dispatch = useDispatch();

  const { hasPermission } = usePermissions();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const cplList:any = {
        users: [],
        cpls: [],
      };
      data?.forEach((item: any) => {
        cplList.users.push(item?.user_name);
        cplList.cpls.push(item?.cpl_total || 0);
      });


      setCplSeries(cplList?.cpls|| []);
      setCplLabels(cplList?.users);
    }
  }, [data]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const cpaList:any = {
        users: [],
        cpas: [],
      };
      data?.forEach((item: any) => {
        cpaList?.users.push(item?.user_name);
        cpaList?.cpas.push(item?.cpa_total || 0);
      })


      setCpaSeries(cpaList?.cpas || []);
      setCpaLabels(cpaList?.users);
    }
  }, [data]);

  const formatRangeDate = (dateRange: [Date | null, Date | null]): any => {
    const [fromDate, toDate] = dateRange;
    setFromDateStr(fromDate ? formatDate(fromDate) : "");
    setToDateStr(toDate ? formatDate(toDate) : "");
  };

  const submitData = () => {
    const filter = {
      from: fromDateStr,
      to: toDateStr,
      team_id: selectedUser.value,
    };
    dispatch(getStatistic(filter));
  };

  const cplTotals = data?.filter((item: any) => item?.cpl_total !== null)
  .map((item: any) => item?.cpl_total || 0);


  // useEffect(() => {
  //   const permission = hasPermission('view-dashboard');
  //   if (!permission) {
  //     navigate('/no-access');
  //   }
  // }, [hasPermission, navigate]);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          <Row>
            <Col md={12}>
              <Card className="card-h-100">
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: 350 }}>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(date: any) => {
                          formatRangeDate(date);
                        }}
                      />
                    </div>
                    <Select
                      isSearchable
                      placeholder="Select user"
                      options={teamsAll.data}
                      onChange={(selectedOption: any) =>
                        setSelectedUser(selectedOption)
                      }
                      value={selectedUser}
                    />
                    <Button
                    className="btn"
                    color="success"
                    disabled={!Boolean(fromDateStr) && !Boolean(toDateStr) && !Boolean(selectedUser)}
                    onClick={submitData}>Search</Button>
                  </div>
                  {!Boolean(fromDateStr) && !Boolean(toDateStr) && !Boolean(selectedUser) && <>choose any data</>}

                  {cplTotals?.length <= 0 ? <NoSearchResult /> :
                  <Row className="align-items-center">
                    {loaded && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: 50,
                          }}
                        >
                          <label>
                            CPL
                          <ReactApexChart
                            options={{ labels: cplLabels }}
                            series={cplSeries}
                            type="pie"
                            width={380}
                          />                
                          </label>
                          <label>
                            CPA
                          <ReactApexChart
                            options={{ labels: cpaLabels }}
                            series={cpaSeries}
                            type="pie"
                            width={380}
                          />
                          </label>
                        </div>
                      </>
                    )}
                  </Row>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
